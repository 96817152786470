import React, { Component } from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';
import { __ } from '../../utilities';

const Wrapper = styled.div`
  display: flex;
  height: 47vh;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-family: ${theme.secondaryFont};
  color: #bbb;
  font-size: ${theme.mdTitle};
  font-weight: 300;
`;

class NotFound extends Component {
  render() {
    return (
      <Wrapper>
        <Title>{__('Nenašiel sa žiaden obsah.')}</Title>
      </Wrapper>
    );
  }
}

export default NotFound;
