import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { loadUrlMap } from './actions';
import Article from '../Article';
import Section from '../Section';
import NotFound from '../NotFound';
import { urlMapIsFetchingSelector, urlMapSelector } from './selectors';
import PropTypes from 'prop-types';
import Loading from '../../components/Loading';

class UrlMap extends PureComponent {
  static init(props) {
    const {
      dispatch,
      location: { pathname },
    } = props;
    const url = pathname.replace(/\//, '');
    return Promise.all([
      dispatch(loadUrlMap(url)).then(data => {
        if (data) {
          const { urltype, object_id } = data;
          const id = String(object_id);
          if (urltype === 'section') {
            return Section.init({ dispatch, id });
          }
          if (urltype === 'article') {
            return Article.init({ dispatch, id });
          }
        }
      }),
    ]);
  }

  componentDidMount() {
    UrlMap.init(this.props).catch(() => {
      // No need to handle 404 error here
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      UrlMap.init(nextProps).catch(() => {
        // No need to handle 404 error here
      });
    }
  }

  render() {
    const { isFetching, urlMap: { urltype, object_id } = {} } = this.props;
    const isLoading = isFetching && !object_id;
    if (isLoading) {
      return <Loading />;
    }

    if (urltype === 'section') {
      return <Section id={object_id} />;
    }

    if (urltype === 'article') {
      return <Article id={object_id} />;
    }

    return <NotFound />;
  }
}

UrlMap.getInitialProps = props => {
  return UrlMap.init(props);
};

UrlMap.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  urlMap: PropTypes.shape({
    urltype: PropTypes.string,
    object_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    isFetching: urlMapIsFetchingSelector(state),
    urlMap: urlMapSelector(state),
  };
};

export default connect(mapStateToProps)(UrlMap);
