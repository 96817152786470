import React from 'react';
import styled from 'styled-components';
import { SectionType } from '../../containers/Section/propTypes';
import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Icon = styled.img`
  margin-right: 0.3em;
`;

const C = ({ section }) => {
  return (
    <Wrapper>
      {section.icon && <Icon src={getMediaManagerUrl(section.icon, 20, 20)} />}
      {section.name}
    </Wrapper>
  );
};

C.propTypes = {
  section: SectionType.isRequired,
};

export default C;
