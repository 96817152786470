import merge from 'lodash/merge';
import {
  FETCH_SECTIONS_REQUEST,
  FETCH_SECTIONS_FAILURE,
  FETCH_SECTIONS_SUCCESS,
  REQUEST_PATH,
  RECEIVE_PATH_SUCCESS,
  RECEIVE_PATH_ERROR,
  REQUEST_STATIC_TEXT,
  RECEIVE_STATIC_TEXT_SUCCESS,
  RECEIVE_STATIC_TEXT_ERROR,
  SET_PATHNAME,
} from './constants';

const entitiesInitialState = {};
const sectionTreeInitialState = {
  tree: [],
  isFetching: false,
};

const entitiesReducer = (state = entitiesInitialState, action) => {
  if (action.payload && action.payload.entities) {
    return merge({}, state, action.payload.entities);
  }
  return state;
};

const sectionTreeReducer = (state = sectionTreeInitialState, action) => {
  switch (action.type) {
    case FETCH_SECTIONS_SUCCESS:
      return {
        ...state,
        tree: action.payload.tree,
        isFetching: false,
      };
    case FETCH_SECTIONS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
      };
    case FETCH_SECTIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    default:
      return state;
  }
};

const pathReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_PATH:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: true,
        },
      };
    case RECEIVE_PATH_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          path: action.payload.path,
          isFetching: false,
          error: null,
        },
      };
    case RECEIVE_PATH_ERROR:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          error: action.payload.error,
          isFetching: false,
        },
      };
    default:
      return state;
  }
};

// Static text
const staticTextsByCidReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_STATIC_TEXT:
      return {
        ...state,
        [action.payload.cid]: {
          ...state[action.payload.cid],
          isFetching: true,
        },
      };
    case RECEIVE_STATIC_TEXT_SUCCESS:
      return {
        ...state,
        [action.payload.cid]: {
          ...state[action.payload.cid],
          isFetching: false,
          error: null,
        },
      };
    case RECEIVE_STATIC_TEXT_ERROR:
      return {
        ...state,
        [action.payload.cid]: {
          ...state[action.payload.cid],
          isFetching: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};

const appPathReducer = (state = '', action) => {
  switch (action.type) {
    case SET_PATHNAME:
      return action.payload.pathName;
    default:
      return state;
  }
};

export {
  entitiesReducer,
  sectionTreeReducer,
  pathReducer,
  staticTextsByCidReducer,
  appPathReducer,
};
