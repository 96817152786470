import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
  @import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900&subset=latin-ext');
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }
  html {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
  }
  * {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  }

  li {
    list-style-type: none;
  }

  #root {
    height: 100%;
    width: 100%;
  }
`;
