import React from 'react';
import { theme } from '../../theme';
import { rem } from 'polished';
import styled from 'styled-components';
import { getProp } from '../../utilities';
import { ServicePricelistType } from '../../containers/Home/propTypes';

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

const Column = styled.td`
  color: #333;
  padding: ${rem(20)};

  &:nth-child(3) {
    color: ${theme.primary};
    position: relative;
    z-index: 5;
    box-shadow: 0px 2px 51px 0 rgba(0, 0, 0, 0.05);
  }

  & > div span:first-child {
    background: #fff;
    font-family: ${theme.secondaryFont};
    font-weight: bold;
    position: relative;
  }

  & > div span:nth-child(2) {
    background-image: linear-gradient(#f5f5f5, rgba(255, 255, 255, 0));
  }

  ${({ theme }) => theme.media.sm`
    width: 200px
  `};
`;

const Row = styled.tr`
  text-align: center;
  padding: ${rem(15)};
  min-height: ${rem(52)};

  & > ${Column}:first-child {
    text-align: left;
  }

  & > ${Column}:nth-child(3) {
    color: ${theme.primary};
  }

  &:nth-child(odd) {
    background: #f9f9f9;
  }

  &:first-child {
    border-bottom: ${rem(2)} solid #eee;
  }

  &:first-child ${Column} {
    font-weight: 600;
    font-family: ${theme.secondaryFont};
    font-size: ${rem(20)};
  }
`;

const PriceList = props => {
  const tableWidgets = getProp(props, 'servicePricelist.content', []).filter(
    widget => widget.type === 'table',
  );
  if (!tableWidgets.length > 0) {
    return false;
  }

  const table = tableWidgets[0].table.map((row, key) => {
    return (
      <Row key={key}>
        {row.map((col, index) => {
          return <Column key={index}>{col}</Column>;
        })}
      </Row>
    );
  });

  return (
    <Content>
      <Table>{table}</Table>
    </Content>
  );
};

PriceList.propTypes = {
  servicePricelist: ServicePricelistType,
};

export default PriceList;
