import { createSelector } from 'reselect';
import { getProp } from '../../utilities';
import { entitiesTypeSelector } from '../App/selectors';

// Current article id
export const currentArticleIdSelector = state => state.currentArticleId;

// Article CONTENT
const articlesByIdDomainSelector = state => state.articlesById;

const currentArticlesByIdSelector = createSelector(
  articlesByIdDomainSelector,
  currentArticleIdSelector,
  (byId, id) => getProp(byId, id, {}),
);

export const currentArticleIsFetchingSelector = createSelector(
  currentArticlesByIdSelector,
  section => getProp(section, 'isFetching', false),
);

export const currentArticleSelector = createSelector(
  entitiesTypeSelector('articles'),
  currentArticleIdSelector,
  (byId, id) => getProp(byId, id, {}),
);
