import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { entitySelector } from '../App/selectors';
import { loadStaticText } from '../App/actions';
import { loadSectionTreeByUniqId } from './actions';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';
import BasicPackage from '../../components/Home/BasicPackage';
import HomeSections from '../../components/Home/HomeSections';
import AdditionalServices from '../../components/Home/AdditionalServices';
import Header from '../Header';
import Background from '../../components/Home/Background';
import {
  SectionListType,
  SectionTreeType,
  DigitalServicesType,
  EmailType,
  PhoneType,
} from './propTypes';
import {
  sectionTreesByUniqIdSectionSelector,
  sectionTreesByUniqIdTreeSelector,
  sectionTreesByUniqIdIsFetchingSelector,
} from './selectors';
import { isFetchinStaticTextSelector } from '../App/selectors';
import { getProp } from '../../utilities';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;
class Home extends Component {
  constructor(props) {
    super(props);
    this.scrollToContactForm = this.scrollToContactForm.bind(this);
  }

  scrollToContactForm() {
    document.getElementById('form').scrollIntoView({ behavior: 'smooth' });
  }

  static init(props) {
    const { dispatch } = props;
    return Promise.all([
      dispatch(loadStaticText('vase-digitalne-sluzby')),
      dispatch(loadSectionTreeByUniqId('zakladny-balik')),
      dispatch(loadSectionTreeByUniqId('doplnkove-sluzby')),
      dispatch(loadSectionTreeByUniqId('my-sportnet-online')),
    ]);
  }

  componentDidMount() {
    Home.init(this.props);
    const shouldScrollToForm = getProp(
      this.props,
      'location.state.scrollToForm',
      false,
    );
    if (shouldScrollToForm) this.scrollToContactForm();
  }

  render() {
    const description = getProp(
      this.props.digitalServices,
      'content.0.description',
      '',
    );
    return (
      <Wrapper>
        <Helmet>
          <title>sportnet.online | www.sportnet.online</title>
          <meta
            property="og:title"
            content="sportnet.online | www.sportnet.online"
          />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={process.env.REACT_APP_HTTP_HOST} />
          <meta property="og:site_name" content="sportnet.online" />
        </Helmet>
        <Header
          location={this.props.location}
          email={this.props.email}
          phone={this.props.phone}
        />
        <Background
          digitalServices={this.props.digitalServices}
          isFetching={this.props.isFetchingBackgorund}
        />
        <BasicPackage
          basicPackage={this.props.basicPackageSection}
          isFetching={this.props.isFetchingBasicPackage}
        />
        <HomeSections
          sections={this.props.basicPackageTree}
          isFetching={this.props.isFetchingBasicPackage}
        />
        <AdditionalServices
          additionalServices={this.props.additionalServicesSection}
          additionalServicesTree={this.props.additionalServicesTree}
          isFetching={this.props.isFetchingAdditionalServices}
        />
        <HomeSections
          sections={this.props.mysportnetonlinePackageTree}
          isFetching={this.props.isFetchingMysportnetonlinePackage}
        />
        <Footer phone={this.props.phone} email={this.props.email} />
      </Wrapper>
    );
  }
}

Home.getInitialProps = props => {
  return Home.init(props);
};

const mapStateToProps = state => {
  return {
    phone: entitySelector('staticTexts', 'telefon')(state),
    email: entitySelector('staticTexts', 'email')(state),

    isFetchingBackgorund: isFetchinStaticTextSelector('vase-digitalne-sluzby')(
      state,
    ),
    digitalServices: entitySelector('staticTexts', 'vase-digitalne-sluzby')(
      state,
    ),

    basicPackageSection: sectionTreesByUniqIdSectionSelector('zakladny-balik')(
      state,
    ),
    basicPackageTree: sectionTreesByUniqIdTreeSelector('zakladny-balik')(state),
    isFetchingBasicPackage: sectionTreesByUniqIdIsFetchingSelector(
      'zakladny-balik',
    )(state),

    mysportnetonlinePackageTree: sectionTreesByUniqIdTreeSelector(
      'my-sportnet-online',
    )(state),
    isFetchingMysportnetonlinePackage: sectionTreesByUniqIdIsFetchingSelector(
      'my-sportnet-online',
    )(state),

    additionalServicesSection: sectionTreesByUniqIdSectionSelector(
      'doplnkove-sluzby',
    )(state),
    additionalServicesTree: sectionTreesByUniqIdTreeSelector(
      'doplnkove-sluzby',
    )(state),
    isFetchingAdditionalServices: sectionTreesByUniqIdIsFetchingSelector(
      'doplnkove-sluzby',
    )(state),
  };
};
Home.propTypes = {
  dispatch: PropTypes.func.isRequired,
  email: EmailType.isRequired,
  phone: PhoneType.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),

  isFetchingBackgorund: PropTypes.bool,
  isFetchingBasicPackage: PropTypes.bool,
  isFetchingAdditionalServices: PropTypes.bool,

  digitalServices: DigitalServicesType.isRequired,
  basicPackageSection: SectionListType.isRequired,
  basicPackageTree: SectionTreeType.isRequired,
  additionalServicesSection: SectionListType.isRequired,
  additionalServicesTree: SectionTreeType.isRequired,
};
export default connect(mapStateToProps)(Home);
