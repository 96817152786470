import {
  REQUEST_ARTICLE,
  RECEIVE_ARTICLE_SUCCESS,
  RECEIVE_ARTICLE_ERROR,
  UPDATE_ARTICLE,
  SET_CURRENT_ARTICLE_ID,
} from './constants';

// Article CONTENT
const articlesByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ARTICLE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: true,
        },
      };
    case RECEIVE_ARTICLE_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: false,
          error: null,
        },
      };
    case UPDATE_ARTICLE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: false,
          error: null,
        },
      };
    case RECEIVE_ARTICLE_ERROR:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};

const setCurrentArticleIdReducer = (state = null, action) => {
  if (action.type === SET_CURRENT_ARTICLE_ID) {
    return action.payload.id;
  }
  return state;
};

export { articlesByIdReducer, setCurrentArticleIdReducer };
