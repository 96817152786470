import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../theme';
import { Link } from 'react-router';
import { rem } from 'polished';
import styled from 'styled-components';
import { Button } from '../../components/Button/Button';
import BackImg from '../../assets/images/background.png';
import BackgroundShape from '../../assets/images/frame.png';
import { getProp, __ } from '../../utilities';
import Loading from '../../components/Loading';
import isEmpty from 'lodash/isEmpty';

const Wrapper = styled.div``;

const BackgroundWrapper = styled.div`
  position: relative;
  background-color: ${theme.secondary};
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: ${rem(700)};
  flex-direction: column;
`;

const BackgroundContent = styled.div`
  width: ${rem(600)};
  margin: ${rem(180)} auto 0 auto;
  align-self: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 ${rem(15)};

  ${({ theme }) => theme.media.sm`
    width: 100%;
  `};

  ${({ theme }) => theme.media.s`
    margin-top: ${rem(140)};
  `};
`;

const HeaderTitle = styled.h1`
  text-align: center;
  font-size: ${theme.lgTitle};
  text-transform: uppercase;
  color: #fff;
  margin: 0 0 ${rem(30)} 0;
  font-weight: 900;
  font-family: ${theme.secondaryFont};
`;

const HeaderDescription = styled.div`
  color: #fff;
  font-family: ${theme.primaryFont};
  font-size: ${rem(16)};
  margin-bottom: ${rem(30)};
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
`;

const MoreAbout = styled(Button)`
  margin-bottom: ${rem(15)};
`;

const Interested = styled(Button)`
  margin-left: ${rem(15)};
  background: transparent;
  border: ${rem(2)} solid #fff;
  margin-bottom: ${rem(15)};

  ${({ theme }) => theme.media.s`
    margin-left: 0;
  `};
`;

const RouterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: stretch;
`;

const Shape = styled.div`
  position: absolute;
  width: 100%;
  bottom: ${rem(-1)};
`;

const ShapeImg = styled.img`
  width: 100%;
  display: block;
`;

const Background = props => {
  const { isFetching, digitalServices } = props;
  const widget = getProp(digitalServices, 'content', []).slice(0, 1);
  const content = widget.map(item => {
    return (
      <BackgroundContent key={item.uri}>
        <HeaderTitle>{item.name}</HeaderTitle>
        <HeaderDescription>{item.description}</HeaderDescription>

        <RouterLink to={item.target && item.target}>
          <MoreAbout>{__('Viac o platforme')}</MoreAbout>
        </RouterLink>

        <RouterLink
          onClick={() =>
            document
              .getElementById('form')
              .scrollIntoView({ behavior: 'smooth' })
          }
        >
          <Interested>{__('Mám záujem')}</Interested>
        </RouterLink>
      </BackgroundContent>
    );
  });
  const isLoading = isFetching && isEmpty(digitalServices);
  return (
    <Wrapper>
      {isLoading ? (
        <Loading />
      ) : (
        <BackgroundWrapper backgroundImage={BackImg}>
          {content}
          <Shape>
            <ShapeImg src={BackgroundShape} alt="Vaše digitálne služby" />
          </Shape>
        </BackgroundWrapper>
      )}
    </Wrapper>
  );
};

Background.propTypes = {
  digitalServices: PropTypes.shape({
    content: PropTypes.shape({
      widgets: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          dimensions: PropTypes.shape({
            H: PropTypes.number,
            W: PropTypes.number,
            X: PropTypes.number,
            Y: PropTypes.number,
          }),
          name: PropTypes.string,
          target: PropTypes.string,
        }),
      ),
    }),
  }),
  isFetching: PropTypes.bool,
};

export default Background;
