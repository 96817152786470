import PropTypes from 'prop-types';

export const SectionType = PropTypes.shape({
  _id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.shape({
    media_url: PropTypes.string,
    public_url: PropTypes.string,
  }),
});
