import { createSelector } from 'reselect';
import { getProp } from '../../utilities';
import at from 'lodash/at';
import { entitiesTypeSelector } from '../App/selectors';

// Articles by smart tag

const articlesBySmartTagDomainSelector = state => state.articlesBySmartTag;

const articleObjectBySmartTagSelector = smartTag =>
  createSelector(
    articlesBySmartTagDomainSelector,
    bySmartTag => getProp(bySmartTag, smartTag, {}),
  );

const articleIdsBySmartTagSelector = smartTag =>
  createSelector(
    articleObjectBySmartTagSelector(smartTag),
    articleObject => getProp(articleObject, 'articles', []),
  );

export const articlesBySmartTagSelector = smartTag =>
  createSelector(
    articleIdsBySmartTagSelector(smartTag),
    entitiesTypeSelector('articles'),
    (ids, articles) => at(articles, ids) || [],
  );

export const isFetchingArticlesBySmartTagSelector = smartTag =>
  createSelector(
    articleObjectBySmartTagSelector(smartTag),
    articleObject => getProp(articleObject, 'isFetching', false),
  );

// Section Trees by UniqId

const sectionTreesByUniqIdDomainSelector = state => state.sectionTreesByUniqId;

const sectionTreesByUniqIdObjectSelector = uniqId =>
  createSelector(
    sectionTreesByUniqIdDomainSelector,
    byUniqId => getProp(byUniqId, uniqId, {}),
  );

export const sectionTreesByUniqIdSectionSelector = uniqId =>
  createSelector(
    sectionTreesByUniqIdObjectSelector(uniqId),
    substate => getProp(substate, 'section', {}),
  );

export const sectionTreesByUniqIdTreeSelector = uniqId =>
  createSelector(
    sectionTreesByUniqIdObjectSelector(uniqId),
    substate => getProp(substate, 'tree', []),
  );

export const sectionTreesByUniqIdIsFetchingSelector = uniqId =>
  createSelector(
    sectionTreesByUniqIdObjectSelector(uniqId),
    substate => getProp(substate, 'isFetching', false),
  );

// Section By uniqId

const sectionsByUniqIdDomainSelector = state => state.sectionsByUniqId;

const sectionsByUniqIdObjectSelector = uniqId =>
  createSelector(
    sectionsByUniqIdDomainSelector,
    byUniqId => getProp(byUniqId, uniqId, {}),
  );

export const sectionsByUniqIdIsFetchingSelector = uniqId =>
  createSelector(
    sectionsByUniqIdObjectSelector(uniqId),
    substate => getProp(substate, 'isFetching', false),
  );

const sectionsByUniqIdSectionIdSelector = uniqId =>
  createSelector(
    sectionsByUniqIdObjectSelector(uniqId),
    substate => {
      const sectionId = getProp(substate, 'sectionId', '');
      return String(sectionId);
    },
  );

export const sectionByUniqIdSelector = uniqId =>
  createSelector(
    sectionsByUniqIdSectionIdSelector(uniqId),
    entitiesTypeSelector('sections'),
    (sectionId, byId) => getProp(byId, sectionId, {}),
  );
