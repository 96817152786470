import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import LogoImg from '../../assets/images/logo-dark.svg';

const Wrapper = styled.div`
  padding: 0 ${rem(15)};
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: ${rem(1200)};
  margin: 0 auto;
  padding: ${rem(60)} 0;

  ${({ theme }) => theme.media.l`
    flex-direction: column
  `};
`;

const LogoWrapper = styled.div`
  & a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${({ theme }) => theme.media.l`
    margin-bottom: ${rem(60)}
  `};
`;

const Logo = styled.img`
  height: ${rem(48)};
`;

const MenuWrapper = styled.div`
  margin-left: ${rem(90)};
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: ${rem(14)};

  ${({ theme }) => theme.media.l`
    margin-left: 0;
    padding-left: ${rem(15)};
  `};

  ${({ theme }) => theme.media.xsm`
    margin-left: 0;
    padding-left: ${rem(0)};
  `};

  & > ul > li {
    font-weight: bold;
    margin-bottom: ${rem(15)};
  }
`;

const FooterTop = props => {
  return (
    <Wrapper>
      <Footer>
        <LogoWrapper>
          <a href="/">
            <Logo src={LogoImg} alt="logo" />
          </a>
        </LogoWrapper>
        <MenuWrapper>{props.sections}</MenuWrapper>
      </Footer>
    </Wrapper>
  );
};

FooterTop.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.node),
};

export default FooterTop;
