import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Header from '../Header';
import Footer from '../Footer';
import { rem } from 'polished';
import { entitySelector } from '../App/selectors';
import { sectionTreeSelector } from '../App/selectors';

const Wrapper = styled.div``;

const Content = styled.div`
  padding: ${rem(30)} ${rem(15)};
  min-height: 52.4vh;
`;

class Page extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: 0,
    };
  }

  render() {
    const { children } = this.props;
    return (
      <Wrapper>
        <Header
          path={this.props}
          phone={this.props.phone}
          email={this.props.email}
        />
        <Content>{children}</Content>
        <Footer phone={this.props.phone} email={this.props.email} />
      </Wrapper>
    );
  }
}

Page.propTypes = {
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  phone: PropTypes.shape({
    content: PropTypes.shape({
      widgets: PropTypes.arrayOf(
        PropTypes.shape({
          header: PropTypes.string,
          text: PropTypes.string,
        }),
      ),
    }),
  }),
  email: PropTypes.shape({
    content: PropTypes.shape({
      widgets: PropTypes.arrayOf(
        PropTypes.shape({
          header: PropTypes.string,
          text: PropTypes.string,
        }),
      ),
    }),
  }),
};

const mapStateToProps = state => {
  return {
    phone: entitySelector('staticTexts', 'telefon')(state),
    email: entitySelector('staticTexts', 'email')(state),
    sectionsTree: sectionTreeSelector(state),
  };
};

export default connect(mapStateToProps)(Page);
