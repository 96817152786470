import styled from 'styled-components';
import { theme } from '../../theme';
import { rem } from 'polished';

export const Button = styled.button`
  background: ${theme.primary};
  border-radius: ${theme.borderRadius};
  color: #fff;
  font-size: ${rem(16)};
  border: 0;
  line-height: 1.5;
  cursor: pointer;
  padding: ${rem(20)} ${rem(30)};
  text-transform: uppercase;
  font-family: ${theme.secondaryFont};
  font-weight: 700;

  &:hover {
    opacity: 0.8;
  }
`;
