import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../../theme';
import { rem } from 'polished';
import { Link } from 'react-router';
import { getArticleUrl, __, getPictureUrl } from '../../utilities';
import { More, MoreArrow } from '../Home/AdditionalServices';
import ArrowImg from '../../assets/images/arrow-more.svg';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ArticleContent = styled.div`
  flex: 0 0 50%;
  padding-right: ${rem(30)};
  padding-top: ${rem(30)};

  &:nth-child(even) {
    padding-right: 0;
  }

  ${({ theme }) => theme.media.m`
    flex: 0 0 100%;
  `};
`;

const ArticleTitle = styled.h3`
  font-family: ${theme.secondaryFont};
  text-transform: uppercase;
  font-size: ${theme.smTitle};
  font-weight: 800;
  margin: 0;
`;

const ArticlePerex = styled.p`
  color: #999;
  font-size: ${rem(14)};
  margin: 0 0 ${rem(10)} 0;
`;

const RouterLink = styled(Link)`
  color: #333;
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    color: ${theme.primary};
    transition: all 0.3s;
  }
`;

const MoreContent = styled(More)`
  font-family: ${theme.primaryFont};
  font-weight: 800;
`;

const Img = styled.img`
  width: ${rem(51)};
  margin-right: ${rem(10)};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(30)};
`;

const Article = props => {
  const { articles } = props;
  const sectionArticles =
    articles &&
    articles.map(item => {
      const img = getPictureUrl(item, 200, null);
      return (
        <ArticleContent key={item._id}>
          <TitleWrapper>
            {img && <Img src={img} alt={item.name} />}
            <ArticleTitle>
              <RouterLink to={getArticleUrl(item)}>{item.name}</RouterLink>
            </ArticleTitle>
          </TitleWrapper>
          <ArticlePerex>{item.perex}</ArticlePerex>
          <RouterLink to={getArticleUrl(item)}>
            <MoreContent>
              {__('viac')}
              <MoreArrow src={ArrowImg} alt={__('viac')} />
            </MoreContent>
          </RouterLink>
        </ArticleContent>
      );
    });

  return <Wrapper>{sectionArticles}</Wrapper>;
};

Article.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      perex: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};

export default Article;
