import PropTypes from 'prop-types';

export const AdditionalServicesType = PropTypes.shape({
  cid: PropTypes.string,
  content: PropTypes.shape({
    name: PropTypes.string,
    widgets: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        table: PropTypes.arrayOf(PropTypes.array),
        header: PropTypes.string,
        text: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
  }),
});

export const ServicePricelistType = PropTypes.shape({
  cid: PropTypes.string,
  content: PropTypes.shape({
    name: PropTypes.string,
    widgets: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        table: PropTypes.arrayOf(PropTypes.array),
      }),
    ),
  }),
  name: PropTypes.string,
  _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const AdditionalServicesArticlesType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    picture: PropTypes.shape({
      uri: PropTypes.string,
    }),
    sectionid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    url: PropTypes.string,
    widgets: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        uri: PropTypes.string,
      }),
    ),
  }),
);

export const ArticlesType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    perex: PropTypes.string,
    picture: PropTypes.shape({
      uri: PropTypes.string,
    }),
    sectionid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    url: PropTypes.string,
    widgets: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        uri: PropTypes.string,
      }),
    ),
    _id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
);

export const BasicPackageType = PropTypes.shape({
  cid: PropTypes.string,
  content: PropTypes.shape({
    name: PropTypes.string,
    widgets: PropTypes.arrayOf(
      PropTypes.shape({
        header: PropTypes.string,
        text: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
  }),
  name: PropTypes.string,
  _id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const DigitalServicesType = PropTypes.shape({
  cid: PropTypes.string,
  content: PropTypes.shape({
    name: PropTypes.string,
    widgets: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        target: PropTypes.string,
        header: PropTypes.string,
        text: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
  }),
  name: PropTypes.string,
  _id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const EmailType = PropTypes.shape({
  cid: PropTypes.string,
  content: PropTypes.shape({
    widgets: PropTypes.arrayOf(
      PropTypes.shape({
        header: PropTypes.string,
        text: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
  }),
  _id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const PhoneType = PropTypes.shape({
  cid: PropTypes.string,
  content: PropTypes.shape({
    widgets: PropTypes.arrayOf(
      PropTypes.shape({
        header: PropTypes.string,
        text: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
  }),
  _id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const ServicePriceListType = PropTypes.shape({
  cid: PropTypes.string,
  content: PropTypes.shape({
    widgets: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        table: PropTypes.arrayOf(PropTypes.array),
        type: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
  }),
  _id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const SectionTreeType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.shape({})),
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    url: PropTypes.string,
  }),
);

export const SectionListType = PropTypes.shape({
  name: PropTypes.string,
  perex: PropTypes.string,
  _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});
