import { sectionIconMap } from './sectionIconMap';
import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';

export const __ = toTranslate => {
  return toTranslate;
};

export const getProp = (object, path, defaultValue = '') => {
  if (path === undefined || path === null || path.length <= 0)
    return defaultValue;
  if (typeof path !== 'string') {
    throw new Error(`Invalid getProp argument path of type: ${typeof path}`);
  }
  const val = path.split('.').reduce(function(o, x) {
    return typeof o === 'undefined' || o === null ? defaultValue : o[x];
  }, object);
  if (typeof val === 'undefined' || val === null) {
    return defaultValue;
  }
  return val;
};

export const getSectionUrl = section => {
  if (section.url) {
    return `/${section.url}`;
  } else {
    return `/section/${section._id}`;
  }
};

export const getArticleUrl = article => {
  if (article.url) {
    return `/${article.url}`;
  } else {
    return `/article/${article._id}`;
  }
};

export const getBannerPictureUri = (item = {}, size = '') => {
  if (item.uri) {
    const [width, height] = size.split('x');
    return getMediaManagerUrl(item, width, height);
  }
};

export const getIdFromProps = props => {
  let id = props.id;
  if (!id) {
    id = getProp(props, 'params.id', '');
  }
  return String(id);
};

export const getPictureForSection = (section = {}) => {
  if (section.uniqid) {
    return sectionIconMap[section.uniqid] || null;
  }
  return null;
};

export const getPictureUrl = (item, width = 600) =>
  getMediaManagerUrl(item.picture, width, 0, 'resize');
