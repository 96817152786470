import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import {
  entitiesReducer,
  sectionTreeReducer,
  pathReducer,
  staticTextsByCidReducer,
  appPathReducer,
} from './containers/App/reducer';
import { urlMap } from './containers/UrlMap/reducer';
import {
  articlesBySmartTagReducer,
  sectionTreesByUniqIdReducer,
  sectionsByUniqIdReducer,
} from './containers/Home/reducer';
import {
  sectionsByIdReducer,
  sectionArticlesBySectionIdReducer,
  setCurrentSectionIdReducer,
} from './containers/Section/reducer';
import {
  articlesByIdReducer,
  setCurrentArticleIdReducer,
} from './containers/Article/reducer';

export default {
  routing: routerReducer,
  form: formReducer,
  entities: entitiesReducer,
  sectionTree: sectionTreeReducer,
  pathBySectionId: pathReducer,
  urlMapByUrl: urlMap,
  currentSectionId: setCurrentSectionIdReducer,
  sectionsById: sectionsByIdReducer,
  sectionArticlesBySectionId: sectionArticlesBySectionIdReducer,
  currentArticleId: setCurrentArticleIdReducer,
  articlesById: articlesByIdReducer,
  staticTextsByCid: staticTextsByCidReducer,
  articlesBySmartTag: articlesBySmartTagReducer,
  pathName: appPathReducer,
  sectionTreesByUniqId: sectionTreesByUniqIdReducer,
  sectionsByUniqId: sectionsByUniqIdReducer,
};
