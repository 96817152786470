import { schema } from 'normalizr';

// Section
export const sectionSchema = new schema.Entity(
  'sections',
  {},
  {
    idAttribute: '_id',
  },
);

// Article
export const articleSchema = new schema.Entity(
  'articles',
  {},
  {
    idAttribute: '_id',
  },
);

// Static text
export const staticTextSchema = new schema.Entity(
  'staticTexts',
  {},
  {
    idAttribute: 'cid',
  },
);
