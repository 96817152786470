import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { rem } from 'polished';
import { theme } from '../../theme';
import { Link } from 'react-router';
import ArrowImg from '../../assets/images/bullet-arrow.svg';
import SectionName from '../SectionName';
import { FooterItem, Icon, ItemText } from '../Footer/FooterMiddle';
import { Button } from '../Button/Button';
import {
  getSectionUrl,
  getBannerPictureUri,
  getProp,
  __,
} from '../../utilities/index';
import { SectionTreeType } from '../../containers/Home/propTypes';
import { withRouter } from 'react-router';

const Wrapper = styled.div`
  max-width: ${rem(1200)};
  padding-top: ${rem(60)};
  margin: 0 auto;

  ${({ theme }) => theme.media.md`
  max-width: ${rem(768)};
  `};
`;

const NavWrapper = styled.div`
  display: flex;

  & > ul {
    margin-bottom: 1em;
  }

  & > ul > li {
    font-size: ${rem(18)};
    font-weight: bold;
    margin-bottom: ${rem(15)};
    display: flex;
    align-items: center;
  }

  ${({ theme }) => theme.media.md`
    flex-direction: column;
    padding-left: 0;
  `};
`;

const ListWrapper = styled.ul`
  padding: 0;
  margin: 0 0 ${rem(60)} 0;
  width: 100%;

  & > ul > li {
    font-size: ${rem(16)};
    line-height: 3;
    width: 70%;
    border-bottom: 1px solid rgba(255, 255, 225, 0.1);

    ${({ theme }) => theme.media.md`
      width: 100%;
      border-bottom: none;
      line-height: 2;
    `} &:last-child {
      border: 0;
    }
  }
`;

const List = styled.li`
  color: #fff;

  ${({ theme }) => theme.media.md`
    text-align: center;
  `} & > li {
    font-size: ${rem(18)};
  }
`;

const SubList = styled.li`
  padding-left: 0.5em;
  > a {
    font-weight: normal;
  }
`;

const RouterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
`;

const Arrow = styled.img`
  margin-right: ${rem(10)};

  ${({ theme }) => theme.media.md`
    display: none;
  `};
`;

const NavFooter = styled.div`
  background: #222;
  border-radius: ${theme.borderRadius};
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding: ${rem(30)};
  flex-direction: row;

  ${({ theme }) => theme.media.l`
    flex-direction: column;
  `};
`;

const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;

  ${({ theme }) => theme.media.l`
    margin-bottom: ${rem(15)};
  `};

  ${({ theme }) => theme.media.md`
  width: 100%;
    align-items: center;
    margin-bottom: ${rem(30)};
  `};
`;

const Title = styled.h4`
  color: #fff;
  font-family: ${theme.secondaryFont};
  margin: 0 0 ${rem(15)} 0;
  font-size: ${theme.smTitle};

  ${({ theme }) => theme.media.md`
    text-align: center;
  `};
`;

const Btn = styled(Button)`
  display: flex;
`;

const RightCol = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  ${({ theme }) => theme.media.md`
    margin-left: 0;
    justify-content: center;
  `};

  ${({ theme }) => theme.media.xsm`
    flex-direction: column;
  `};
`;

const Href = styled.a`
  color: #fff;
  text-decoration: none;
`;

const bodyOverflowHiddenClass = 'responsive-menu-opened';

const GlobalStyle = createGlobalStyle`
body.${bodyOverflowHiddenClass} {
  overflow: hidden;
  #root {
    overflow: hidden;
  }
}
`;

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.scrollToFormHandler = this.scrollToFormHandler.bind(this);
  }

  scrollToFormHandler() {
    if (this.props.location.pathname === '/' && document) {
      document.body.classList.remove(bodyOverflowHiddenClass);
      document.getElementById('form').scrollIntoView({ behavior: 'smooth' });
    } else {
      this.props.router.push({
        pathname: '/',
        state: {
          scrollToForm: true,
        },
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.visible === true && this.props.visible === false) {
      document.body.classList.add(bodyOverflowHiddenClass);
    }
    if (nextProps.visible === false && this.props.visible === true) {
      document.body.classList.remove(bodyOverflowHiddenClass);
    }
  }

  componentWillUnmount() {
    if (document) {
      if (document.body.classList.contains(bodyOverflowHiddenClass)) {
        document.body.classList.remove(bodyOverflowHiddenClass);
      }
    }
  }

  render() {
    const tree = getProp(this.props, 'sectionTree', []);
    const { pathname } = this.props;
    const sections = tree.map(section => {
      const subSections =
        section.sections &&
        section.sections.length > 0 &&
        section.sections.map(subSection => {
          return (
            <SubList key={subSection._id} onClick={this.props.onClickLink}>
              <Arrow src={ArrowImg} alt={subSection.name} />
              <RouterLink to={getSectionUrl(subSection)}>
                <SectionName section={subSection} />
              </RouterLink>
            </SubList>
          );
        });
      return (
        <ListWrapper key={section._id}>
          <List>
            <RouterLink
              onClick={this.props.onClickLink}
              to={getSectionUrl(section)}
            >
              <SectionName section={section} />
            </RouterLink>
          </List>
          {subSections}
        </ListWrapper>
      );
    });

    const emailWidget = getProp(this.props.email, 'content', []);
    const phoneWidget = getProp(this.props.phone, 'content', []);
    const email = emailWidget.map(item => {
      return (
        <FooterItem key={item.uri}>
          <Icon
            src={getBannerPictureUri(item, '0x41')}
            alt={item.description}
          />
          <ItemText>
            <Href href={item.target}>{item.description}</Href>
          </ItemText>
        </FooterItem>
      );
    });

    const phone = phoneWidget.map(item => {
      return (
        <FooterItem item={item.uri} key={item.uri}>
          <Icon
            src={getBannerPictureUri(item, '0x41')}
            alt={item.description}
          />
          <ItemText>
            <Href href={item.target}>{item.description}</Href>
          </ItemText>
        </FooterItem>
      );
    });

    return (
      <React.Fragment>
        <GlobalStyle />
        <Wrapper pathname={pathname}>
          <NavWrapper>{sections}</NavWrapper>
          <NavFooter>
            <LeftCol>
              <Title>{__('Máte záujem využívať sportnet.online?')}</Title>
              <Btn
                onClick={() => {
                  this.props.onClickLink();
                  this.scrollToFormHandler();
                }}
              >
                {__('Vyžiadať ponuku')}
              </Btn>
            </LeftCol>
            <RightCol>
              {phone}
              {email}
            </RightCol>
          </NavFooter>
        </Wrapper>
      </React.Fragment>
    );
  }
}

Navigation.propTypes = {
  email: PropTypes.shape({
    cid: PropTypes.string,
    content: PropTypes.shape({
      widgets: PropTypes.arrayOf(
        PropTypes.shape({
          header: PropTypes.string,
          text: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
    }),
  }),
  phone: PropTypes.shape({
    cid: PropTypes.string,
    content: PropTypes.shape({
      widgets: PropTypes.arrayOf(
        PropTypes.shape({
          header: PropTypes.string,
          text: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
    }),
  }),
  sectionTree: SectionTreeType.isRequired,
  visible: PropTypes.bool.isRequired,
  onClickLink: PropTypes.func.isRequired,
  pathname: PropTypes.string,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Navigation);
