import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import LoadingImg from '../../assets/images/rolling.svg';

const Wrapper = styled.div`
  height: 47vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Circle = styled.img`
  height: ${rem(130)};
`;

const Loading = () => {
  return (
    <Wrapper>
      <Circle src={LoadingImg} alt="loading" />
    </Wrapper>
  );
};

export default Loading;
