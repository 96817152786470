import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from 'react-router';
import { theme } from '../../theme';
import UserIconImg from '../../assets/images/user.svg';
import NavIconImg from '../../assets/images/nav.svg';
import CloseIconImg from '../../assets/images/close.svg';
import LogoLight from '../../assets/images/logo-light.svg';
import LogoDark from '../../assets/images/logo-dark.svg';
import { __ } from '../../utilities';

const Wrapper = styled.div``;

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${rem(30)} 0;
  max-width: ${rem(1200)};
  margin: 0 auto;
  color: #fff;
  align-self: flex-start;
  font-family: ${theme.secondaryFont};
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  & > a {
    margin-left: 0;
  }
`;

const LogoImg = styled.img`
  height: ${rem(48)};
  display: block;

  ${({ theme }) => theme.media.s`
    height: ${rem(38)}
  `};
`;

const Nav = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  height: ${rem(48)};
`;

const RouterLink = styled(Link)`
  text-decoration: none;
  display: flex;
`;

const Icon = styled.img`
  margin-right: ${rem(10)};
`;

const IconWrapper = styled.div`
  font-size: ${rem(14)};
  color: ${props => (props.visible || props.path !== '/' ? '#000033' : '#fff')};
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: ${rem(48)};
  margin-left: ${rem(30)};
`;

const NavBarWrapper = styled.div`
  width: 100%;
  position: absolute;
  z-index: 100;
  top: 0;
  padding: 0 ${rem(15)};
  background: #fff;
  ${props =>
    props.path === '/'
      ? {
          boxShadow: 'none',
          background: 'transparent',
        }
      : {
          boxShadow: '0px 2px 51px 0 rgba(0, 0, 0, 0.1)',
          background: '#fff',
          position: 'static',
        }};
  ${props =>
    props.visible && {
      background: '#fff',
      position: 'fixed',
    }};
`;

const HeaderItem = styled.div`
  display: block;

  ${({ theme }) => theme.media.sm`
    display: none;
  `};
`;

const Navbar = props => {
  return (
    <Wrapper>
      <NavBarWrapper visible={props.visible} path={props.path}>
        <NavBar>
          <LogoWrapper>
            <RouterLink to="/">
              <LogoImg
                src={props.visible || props.path !== '/' ? LogoLight : LogoDark}
                alt="logo"
              />
            </RouterLink>
          </LogoWrapper>
          <Nav>
            <IconWrapper
              onClick={props.onMenuClick}
              visible={props.visible}
              path={props.path}
            >
              <Icon
                src={props.visible ? CloseIconImg : NavIconImg}
                alt={__('Navigácia')}
              />
              <HeaderItem>{__('menu')}</HeaderItem>
            </IconWrapper>
            <RouterLink href="https://my.sportnet.online">
              <IconWrapper visible={props.visible} path={props.path}>
                <Icon src={UserIconImg} />
                <HeaderItem>my.sportnet.online</HeaderItem>
              </IconWrapper>
            </RouterLink>
          </Nav>
        </NavBar>
      </NavBarWrapper>
    </Wrapper>
  );
};

Navbar.propTypes = {
  onMenuClick: PropTypes.func.isRequired,
  path: PropTypes.string,
  visible: PropTypes.bool,
  pathname: PropTypes.string,
};

export default Navbar;
