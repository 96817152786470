import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from 'react-router';
import { theme } from '../../theme';
import { getSectionUrl } from '../../utilities';

const RouterLink = styled(Link)`
  color: #333;
  text-decoration: none;
  font-size: ${rem(12)};
  margin-right: ${rem(10)};

  &::after {
    margin-left: ${rem(10)};
    content: '/';
  }

  &:last-child::after {
    content: '';
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > ${RouterLink}:last-child::after {
    content: '';
  }
`;

const BreadCrumbsWrapper = styled.div`
  padding-bottom: ${rem(15)};
`;

const LinesWrapper = styled.div`
  display: flex;
`;

const Line = styled.div`
  height: ${rem(1)};
  width: 100%;
  background: #eee;

  &:first-child {
    width: ${rem(40)};
    background: ${theme.primary};
  }
`;

const BreadCrumbs = props => {
  const breadcrumb = props.path.map(item => {
    return (
      <RouterLink key={item._id} to={getSectionUrl(item)}>
        {item.name}
      </RouterLink>
    );
  });
  return (
    <Wrapper>
      <BreadCrumbsWrapper>{breadcrumb}</BreadCrumbsWrapper>
      <LinesWrapper>
        <Line />
        <Line />
      </LinesWrapper>
    </Wrapper>
  );
};

BreadCrumbs.propTypes = {
  path: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      _id: PropTypes.number,
    }),
  ),
};

export default BreadCrumbs;
