import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import commonReducers from './reducer.js';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { browserHistory } from 'react-router';
import CsmApi from './CsmApi';

if (process.env.REACT_APP_CSM_API_BASE_URL) {
  CsmApi.setBaseUrl(process.env.REACT_APP_CSM_API_BASE_URL);
} else {
  throw Error('REACT_APP_CSM_API_BASE_URL not set');
}

let composeEnhancers;
if (typeof window !== 'undefined') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

export default function configureStore(preloadedState) {
  if (!preloadedState) {
    preloadedState = {};
  }
  return createStore(
    combineReducers(
      Object.assign({}, commonReducers, {
        routing: routerReducer,
      }),
    ),
    preloadedState,
    composeEnhancers(
      applyMiddleware(routerMiddleware(browserHistory), thunkMiddleware),
    ),
  );
}
