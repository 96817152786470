const namespace = 'Section';

//Section CONTENT
export const REQUEST_SECTION = `${namespace}/REQUEST_SECTION`;
export const RECEIVE_SECTION_SUCCESS = `${namespace}/RECEIVE_SECTION_SUCCESS`;
export const UPDATE_SECTION = `${namespace}/UPDATE_SECTION`;
export const RECEIVE_SECTION_ERROR = `${namespace}/RECEIVE_SECTION_ERROR`;

//Section ARTICLES
export const REQUEST_SECTION_ARTICLES = `${namespace}/REQUEST_SECTION_ARTICLES`;
export const RECEIVE_SECTION_ARTICLES_SUCCESS = `${namespace}/RECEIVE_SECTION_ARTICLES_SUCCESS`;
export const RECEIVE_SECTION_ARTICLES_ERROR = `${namespace}/RECEIVE_SECTION_ARTICLES_ERROR`;

export const SET_CURRENT_SECTION_ID = `${namespace}/SET_CURRENT_SECTION_ID`;
