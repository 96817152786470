import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme } from '../../theme';
import { Link } from 'react-router';
import ArrowMore from '../../assets/images/arrow-more.svg';
import { getSectionUrl, getProp, __, getPictureUrl } from '../../utilities';
import Loading from '../../components/Loading';
import {
  SectionListType,
  SectionTreeType,
} from '../../containers/Home/propTypes';
import { RouterLink as TitleLink } from './BasicPackage';
import isEmpty from 'lodash/isEmpty';

const Wrapper = styled.div`
  background: #f9f9f9;
  padding: 0 ${rem(15)};
`;

const Content = styled.div`
  max-width: ${rem(1200)};
  margin: 0 auto;
  padding: ${rem(60)} 0;

  ${({ theme }) => theme.media.lg`
    max-width: ${rem(900)};
  `};

  ${({ theme }) => theme.media.md`
    max-width: ${rem(768)};
  `};
`;

const Heading = styled.div`
  font-family: ${theme.secondaryFont};
  text-transform: uppercase;
  font-weight: bold;
  color: ${theme.primary};
  font-size: ${rem(14)};

  ${({ theme }) => theme.media.sm`
    text-align:center;
  `};
`;

const Title = styled.h2`
  font-family: ${theme.secondaryFont};
  text-transform: uppercase;
  font-weight: 800;
  font-size: ${theme.mdTitle};
  color: #333;
  margin: ${rem(15)} 0 ${rem(30)} 0;

  ${({ theme }) => theme.media.sm`
    text-align:center;
  `};
`;
const Description = styled.p`
  color: #999;
  font-size: ${rem(14)};
  line-height: 1.5;
  margin-bottom: ${rem(60)};

  ${({ theme }) => theme.media.sm`
    text-align:center;
  `};
`;

const Articles = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Article = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${rem(30)};

  ${({ theme }) => theme.media.xsm`
    width: 100%;
  `};
`;

const Icon = styled.img`
  width: ${rem(41)};
  height: 100%;
`;

const TitleWrapper = styled.div`
  margin-left: ${rem(15)};
  text-decoration: none;
  align-self: center;
`;

const ArticleTitle = styled.h4`
  font-family: ${theme.secondaryFont};
  text-transform: uppercase;
  margin: 0;
  color: #333;
`;

export const More = styled.span`
  font-family: ${theme.primaryFont};
  font-size: ${rem(14)};
  color: #999;
`;

export const MoreArrow = styled.img`
  margin-left: ${rem(10)};
`;

const RouterLink = styled(Link)`
  text-decoration: none;
  display: flex;
`;

const AdditionalServices = props => {
  const { additionalServices, additionalServicesTree, isFetching } = props;
  const serviceCategoryName = __('Potrebujete viac?');
  const title = getProp(additionalServices, 'name', '');
  const text = getProp(additionalServices, 'perex', '');

  const servicesWidget = (
    <div>
      <Heading>{serviceCategoryName}</Heading>
      <Title>
        <TitleLink to={getSectionUrl(additionalServices)}>{title}</TitleLink>
      </Title>
      <Description>{text}</Description>
    </div>
  );

  const articlesWidget = additionalServicesTree.map(item => {
    const img = getPictureUrl(item, 200, null);
    return (
      <Article key={item._id}>
        <RouterLink to={getSectionUrl(item)}>
          {img && <Icon src={img} alt={item.name} />}
          <TitleWrapper>
            <ArticleTitle>{item.name}</ArticleTitle>
            <More>
              {__('viac')}
              <MoreArrow src={ArrowMore} alt={item.name} />
            </More>
          </TitleWrapper>
        </RouterLink>
      </Article>
    );
  });
  const isLoading = isFetching && isEmpty(additionalServices);
  return (
    <Wrapper>
      {isLoading ? (
        <Loading />
      ) : (
        <Content>
          {servicesWidget}
          <Articles>{articlesWidget}</Articles>
        </Content>
      )}
    </Wrapper>
  );
};

AdditionalServices.propTypes = {
  additionalServices: SectionListType.isRequired,
  additionalServicesTree: SectionTreeType.isRequired,
  isFetching: PropTypes.bool,
};

export default AdditionalServices;
