import CsmApi from '../../CsmApi';
import {
  REQUEST_URLMAP,
  RECEIVE_URLMAP_SUCCESS,
  RECEIVE_URLMAP_ERROR,
} from './constants';
import { updateSection } from '../Section/actions';
import { updateArticle } from '../Article/actions';
import { sectionSchema, articleSchema } from '../../schemas';
import { normalize } from 'normalizr';
import { contentSpaceSelector } from '../App/selectors';
import { NotFoundError } from '../../errors';

export const requestUrlMap = url => ({
  type: REQUEST_URLMAP,
  payload: {
    url,
  },
});

export const receiveUrlMapSuccess = (url, urlMap) => ({
  type: RECEIVE_URLMAP_SUCCESS,
  payload: {
    url,
    urltype: urlMap.urltype,
    object_id: urlMap.object_id,
  },
});

export const receiveUrlMapError = (url, error) => ({
  type: RECEIVE_URLMAP_ERROR,
  payload: {
    url,
    error,
  },
});

export const loadUrlMap = url => {
  return (dispatch, getState) => {
    dispatch(requestUrlMap(url));
    const contentSpace = contentSpaceSelector(getState());
    return CsmApi.getContentByUrl(contentSpace, {
      urlPath: url,
      expandObject: 1,
    })
      .then(data => {
        dispatch(receiveUrlMapSuccess(url, data));
        if (data.urltype === 'section') {
          const normalized = normalize(data.expanded_object, sectionSchema);
          dispatch(updateSection(normalized.result, normalized.entities));
        }
        if (data.urltype === 'article') {
          const normalized = normalize(data.expanded_object, articleSchema);
          dispatch(updateArticle(normalized.result, normalized.entities));
        }
        return data;
      })
      .catch(error => {
        dispatch(receiveUrlMapError(url, error));
        throw new NotFoundError();
      });
  };
};
