import { createSelector } from 'reselect';
import { getProp } from '../../utilities';

const currentUrlSelector = state =>
  state.pathName.replace(/\/$/, '').replace(/^\//, '');

const urlMapDomainSelector = state => state.urlMapByUrl;

const currentUrlMapSelector = createSelector(
  urlMapDomainSelector,
  currentUrlSelector,
  (byUrl, currentUrl) => {
    const item = byUrl[currentUrl];
    if (item) {
      return item;
    }
    return {};
  },
);

export const urlMapIsFetchingSelector = createSelector(
  currentUrlMapSelector,
  urlMap => getProp(urlMap, 'isFetching', false),
);

export const urlMapSelector = createSelector(
  currentUrlMapSelector,
  urlMap => getProp(urlMap, 'urlMap', {}),
);
