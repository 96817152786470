import React, { Component } from 'react';
import {
  getProp,
  getIdFromProps,
  getPictureUrl,
  getArticleUrl,
} from '../../utilities';
import { loadArticle, setCurrentArticleId } from './actions';
import { connect } from 'react-redux';
import {
  currentArticleIsFetchingSelector,
  currentArticleSelector,
} from './selectors';
import PropTypes from 'prop-types';
import { pathSelector } from '../App/selectors';
import { loadPath } from '../App/actions';
import styled from 'styled-components';
import { ArticleType } from './propTypes';
import { SectionType } from '../Section/propTypes';
import { withRouter } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import NotFound from '../NotFound';
import { Helmet } from 'react-helmet';
import LeftMenu from '../../components/Page/LeftMenu';
import BreadCrumbs from '../../components/Page/BreadCrumbs';
import { sectionTreeSelector } from '../App/selectors';
import { rem } from 'polished';
import { PageTitle, PagePerex } from '../../components/Page/Title';
import Widgets from '../../components/Widgets';
import { ArticlesType, SectionTreeType } from '../Home/propTypes';

const Wrapper = styled.div``;

const Content = styled.div`
  max-width: ${rem(1200)};
  margin: 0 auto;
  display: flex;
  flex-direction: row;

  ${({ theme }) => theme.media.sm`
    flex-direction:column;
  `};
`;

const LeftCol = styled.div`
  width: ${rem(350)};
  display: flex;
  flex-direction: column;
`;

const RightCol = styled.div`
  width: ${rem(850)};
  display: flex;
  flex-direction: column;
  margin-left: ${rem(30)};

  ${({ theme }) => theme.media.sm`
    width: 100%;
    margin-left: 0;
  `};
`;

class Article extends Component {
  componentDidMount() {
    Article.init(this.props).catch(() => {
      // No need to handle 404 error here
    });
  }

  static init(props) {
    const { dispatch } = props;
    const id = getIdFromProps(props);
    return Promise.all([
      dispatch(setCurrentArticleId(id)),
      dispatch(loadArticle(id)).then(data => {
        const sectionId = getProp(data, 'sectionid', null);
        if (sectionId) {
          return Promise.all([dispatch(loadPath(sectionId))]);
        }
      }),
    ]);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentArticleId(null));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      Article.init(nextProps).catch(() => {
        // No need to handle 404 error here
      });
    }
  }

  render() {
    const { isFetching, article, path } = this.props;
    const contentWidgets = getProp(article, 'widgets', []);

    if (!isFetching && isEmpty(article)) {
      return <NotFound />;
    }
    const img = getPictureUrl(article, 1200, null);
    const articlePerex = article.perex;
    return (
      <Wrapper>
        <Helmet>
          <title>{article ? article.name : ''}</title>
          <meta property="og:type" content="article" />
          <meta property="og:title" content={article.name} />
          {articlePerex && (
            <meta property="og:description" content={articlePerex} />
          )}
          {img && <meta property="og:image" content={img} />}
          <meta
            property="og:url"
            content={process.env.REACT_APP_HTTP_HOST + getArticleUrl(article)}
          />
          <meta property="og:site_name" content="sportnet.online" />
        </Helmet>
        <Content>
          <LeftCol>
            <LeftMenu
              sectionTree={this.props.sectionsTree}
              activePathIds={path.map(item => String(item._id))}
            />
          </LeftCol>
          <RightCol>
            <BreadCrumbs path={this.props.path} />
            <PageTitle>{article.name || ''}</PageTitle>
            {article.perex && <PagePerex>{article.perex}</PagePerex>}
            <Widgets widgets={contentWidgets} />
          </RightCol>
        </Content>
      </Wrapper>
    );
  }
}

Article.getInitialProps = props => {
  return Article.init(props);
};

const mapStateToProps = state => {
  const article = currentArticleSelector(state);
  const sectionId = getProp(article, 'sectionid');
  return {
    article,
    isFetching: currentArticleIsFetchingSelector(state),
    path: pathSelector(sectionId)(state),
    sectionsTree: sectionTreeSelector(state),
  };
};

Article.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  article: ArticleType.isRequired,
  path: PropTypes.arrayOf(SectionType).isRequired,
  articles: ArticlesType,
  sectionsTree: SectionTreeType,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

const routerConnected = withRouter(Article);
const reduxConnected = connect(mapStateToProps)(routerConnected);
export default reduxConnected;
