import React, { Component } from 'react';
import {
  getProp,
  getIdFromProps,
  getPictureUrl,
  getSectionUrl,
} from '../../utilities';
import { rem } from 'polished';
import {
  loadSection,
  loadSectionArticles,
  setCurrentSectionId,
} from './actions';
import { connect } from 'react-redux';
import {
  currentSectionIsFetchingSelector,
  currentSectionArticlesSelector,
  currentSectionArticlesIsFetchingSelector,
  currentSectionSelector,
} from './selectors';
import PropTypes from 'prop-types';
import { pathSelector } from '../App/selectors';
import { loadPath } from '../App/actions';
import styled from 'styled-components';
import { SectionType } from './propTypes';
import { ArticleType } from '../Article/propTypes';
import { withRouter } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import NotFound from '../NotFound';
import LeftMenu from '../../components/Page/LeftMenu';
import { Helmet } from 'react-helmet';
import { sectionTreeSelector } from '../App/selectors';
import BreadCrumbs from '../../components/Page/BreadCrumbs';
import Articles from '../../components/Page/Article';
import { PageTitle, PagePerex } from '../../components/Page/Title';
import PriceList from '../../components/Page/PriceList';
import Widgets from '../../components/Widgets';
import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';

const Wrapper = styled.div``;

const Content = styled.div`
  max-width: ${rem(1200)};
  margin: 0 auto;
  display: flex;
  flex-direction: row;

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
  `};
`;

const LeftCol = styled.div`
  max-width: ${rem(263)};
  flex-shrink: 0;
  flex-basis: 100%;
`;

const RightCol = styled.div`
  margin-left: ${rem(30)};

  ${({ theme }) => theme.media.sm`
    margin-left: 0;
  `};
`;

class Section extends Component {
  static init(props) {
    const { dispatch } = props;
    const id = getIdFromProps(props);
    return Promise.all([
      dispatch(setCurrentSectionId(id)),
      dispatch(loadSection(id)),
      dispatch(loadSectionArticles(id)),
      dispatch(loadPath(id)),
    ]);
  }

  componentDidMount() {
    Section.init(this.props).catch(() => {
      // No need to handle 404 error here
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      Section.init(nextProps).catch(() => {
        // No need to handle 404 error here
      });
    }
  }

  render() {
    const { isFetching, section, path } = this.props;
    const perex = getProp(section, 'perex', null);
    if (!isFetching && isEmpty(section)) {
      return <NotFound />;
    }
    let contentWidgets = getProp(section, 'content', []);
    let specialTable = null;
    if (section.uniqid === 'cennik-sluzieb') {
      specialTable = contentWidgets[0];
      contentWidgets = contentWidgets.slice(1);
    }
    const img = getPictureUrl(section, 1200, null);
    const sectionPerex = section.perex;
    return (
      <Wrapper>
        <Helmet>
          <title>{section ? section.name : ''}</title>
          <meta property="og:type" content="article" />
          <meta property="og:title" content={section.name} />
          {sectionPerex && (
            <meta property="og:description" content={sectionPerex} />
          )}
          {img && <meta property="og:image" content={img} />}
          <meta
            property="og:url"
            content={process.env.REACT_APP_HTTP_HOST + getSectionUrl(section)}
          />
          <meta property="og:site_name" content="sportnet.online" />
        </Helmet>
        <Content>
          <LeftCol>
            <LeftMenu
              sectionTree={this.props.sectionsTree}
              activePathIds={path.map(item => String(item._id))}
            />
          </LeftCol>
          <RightCol>
            <BreadCrumbs path={this.props.path} />
            <PageTitle
              icon={
                section.icon
                  ? getMediaManagerUrl(section.icon, 50, 50, 'resize')
                  : null
              }
            >
              {section.name || ''}
            </PageTitle>
            {perex && <PagePerex>{perex}</PagePerex>}
            {specialTable && <PriceList servicePricelist={section} />}
            <Widgets widgets={contentWidgets} />
            <Articles articles={this.props.sectionArticles} />
          </RightCol>
        </Content>
      </Wrapper>
    );
  }
}

Section.getInitialProps = props => {
  return Section.init(props);
};

const mapStateToProps = state => {
  const section = currentSectionSelector(state);
  const sectionId = String(getProp(section, '_id'));
  return {
    section,
    isFetching: currentSectionIsFetchingSelector(state),
    sectionArticles: currentSectionArticlesSelector(state),
    sectionArticlesIsFetching: currentSectionArticlesIsFetchingSelector(state),
    path: pathSelector(sectionId)(state),
    sectionsTree: sectionTreeSelector(state),
  };
};

Section.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sectionArticles: PropTypes.arrayOf(ArticleType).isRequired,
  isFetching: PropTypes.bool.isRequired,
  section: SectionType.isRequired,
  path: PropTypes.arrayOf(SectionType),
  sectionsTree: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

const routerConnected = withRouter(Section);
const reduxConnected = connect(mapStateToProps)(routerConnected);
export default reduxConnected;
