import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import ArrowImg from '../../assets/images/bullet-arrow.svg';
import SectionName from '../SectionName';
import { Link } from 'react-router';
import { getSectionUrl } from '../../utilities';

const Wrapper = styled.div`
  background: #fff;
  box-shadow: 0px 2px 51px 0 rgba(0, 0, 0, 0.1);
  border-radius: ${rem(4)};
  overflow: hidden;

  ${({ theme }) => theme.media.sm`
    display: none;
  `};
`;

const MainListWrapper = styled.ul`
  padding-left: 0;
  display: flex;
  flex-direction: column;
  line-height: 3;
  margin: 0;

  & > li > ul {
    background: #f9f9f9;
  }

  & > li > ul > li > a {
    padding-left: ${rem(37)};
  }
`;

const MainList = styled.li`
  display: flex;
  flex-direction: column;
  color: #333;

  &:last-child {
    border: 0;
  }
`;

const Arrow = styled.img`
  padding: ${rem(15)};
  align-self: center;
  transform: ${props => props.isactive && 'rotate(90deg)'};
`;

const RouterLink = styled(Link)`
  color: #333;
  display: flex;
  text-decoration: none;
  border-bottom: 1px solid #eee;
  font-weight: ${props => props.isactive && 'bold'};
  transition: all 0.3s;

  &:hover {
    background: #f4f4f4;
    transition: all 0.3s;
  }
`;

class LeftMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const tree = this.props.sectionTree;
    const { activePathIds } = this.props;

    const renderSubsections = section => {
      if (activePathIds.includes(section._id)) {
        return (
          section.sections &&
          section.sections.length > 0 &&
          section.sections.map(subsection => {
            return (
              <MainList key={subsection._id}>
                <RouterLink to={getSectionUrl(subsection)}>
                  <SectionName section={subsection} />
                </RouterLink>
              </MainList>
            );
          })
        );
      }
    };

    const sideMenu =
      tree &&
      tree.map(mainItem => {
        const active = activePathIds.includes(mainItem._id);
        return (
          <MainList key={mainItem._id}>
            <RouterLink isactive={active ? 1 : 0} to={getSectionUrl(mainItem)}>
              <Arrow
                src={ArrowImg}
                isactive={active ? 1 : 0}
                alt={mainItem.name}
              />
              <SectionName section={mainItem} />
            </RouterLink>
            <MainListWrapper>{renderSubsections(mainItem)}</MainListWrapper>
          </MainList>
        );
      });

    return (
      <Wrapper>
        <MainListWrapper>{sideMenu}</MainListWrapper>
      </Wrapper>
    );
  }
}

LeftMenu.propTypes = {
  sectionTree: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      _id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  activePathIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  ).isRequired,
};

export default LeftMenu;
