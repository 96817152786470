import {
  REQUEST_URLMAP,
  RECEIVE_URLMAP_SUCCESS,
  RECEIVE_URLMAP_ERROR,
} from './constants';

export const urlMap = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_URLMAP:
      return {
        ...state,
        [action.payload.url]: {
          ...state[action.payload.url],
          isFetching: true,
          error: null,
        },
      };
    case RECEIVE_URLMAP_SUCCESS: {
      return {
        ...state,
        [action.payload.url]: {
          ...state[action.payload.url],
          isFetching: false,
          urlMap: {
            urltype: action.payload.urltype,
            object_id: action.payload.object_id,
          },
          error: null,
        },
      };
    }
    case RECEIVE_URLMAP_ERROR:
      return {
        ...state,
        [action.payload.url]: {
          ...state[action.payload.url],
          isFetching: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};
