import React from 'react';
import styled from 'styled-components';
import { theme, sizes } from '../../theme';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import { getProp, __, getSectionUrl, getPictureUrl } from '../../utilities';
import { SectionListType } from '../../containers/Home/propTypes';
import Loading from '../../components/Loading';
import { Link } from 'react-router';
import isEmpty from 'lodash/isEmpty';

const Wrapper = styled.div`
  padding: 0 ${rem(15)};
  margin-bottom: ${rem(60)};
`;

const Content = styled.div`
  display: flex;
  max-width: ${sizes.lg}px;
  margin: 0 auto;

  ${({ theme }) => theme.media.md`
    align-items: center;
    width: 100%;
    flex-direction: column;
  `};
`;

const LeftCol = styled.div`
  width: 50%;
  padding: 2rem 1rem 1rem 0;
  text-align: right;

  > img {
    max-width: 80%;
  }

  ${({ theme }) => theme.media.md`
    width: 100%;
    text-align: center;
    > img {
      max-width: 100%;
    }
  `};
`;

const Image = styled.img`
  ${({ theme }) => theme.media.md`
  max-width: 100%;
  height: auto;
  align-self: flex-start;
  `};
`;

const RightCol = styled.div`
  width: ${rem(400)};
  margin-left: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  ${({ theme }) => theme.media.md`
    width: 100%;
    flex-direction: column;
  `};
`;

const Category = styled.span`
  font-family: ${theme.secondaryFont};
  color: ${theme.primary};
  font-size: ${rem(14)};
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: ${rem(15)};

  ${({ theme }) => theme.media.md`
    text-align: center;
  `};
`;

const Title = styled.h2`
  font-family: ${theme.secondaryFont};
  font-size: ${theme.lgTitle};
  text-transform: uppercase;
  margin: 0 0 ${rem(30)} 0;
  color: ${theme.secondary};
  font-weight: 800;

  ${({ theme }) => theme.media.md`
    text-align: center;
  `};
`;

const Description = styled.div`
  color: #999;
  font-family: ${theme.primaryFont};

  ${({ theme }) => theme.media.md`
    text-align: justify;
  `};
`;

export const RouterLink = styled(Link)`
  color: ${theme.secondary};
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
`;

const BasicPackage = props => {
  const { basicPackage, isFetching } = props;
  const categoryName = __('jednoduchá správa aplikácií');
  const title = getProp(basicPackage, 'name', '');
  const text = getProp(basicPackage, 'perex', '');
  const isLoading = isFetching && isEmpty(basicPackage);
  const img = getPictureUrl(basicPackage, 600, null);
  return (
    <Wrapper>
      {isLoading ? (
        <Loading />
      ) : (
        <Content>
          <LeftCol>{img && <Image src={img} alt={title} />}</LeftCol>
          <RightCol>
            <Category>{categoryName}</Category>
            <RouterLink to={getSectionUrl(basicPackage)}>
              <Title>{title}</Title>
            </RouterLink>
            <Description>{text}</Description>
          </RightCol>
        </Content>
      )}
    </Wrapper>
  );
};

BasicPackage.propTypes = {
  basicPackage: SectionListType,
  isFetching: PropTypes.bool,
};

export default BasicPackage;
