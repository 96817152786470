import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { theme } from '../../theme';
import { rem } from 'polished';
import styled from 'styled-components';
import { sectionTreeSelector } from '../App/selectors';
import Navigation from '../../components/Header/Navigation';
import { Motion, spring } from 'react-motion';
import Navbar from '../../components/Header/Navbar';
import { PhoneType, EmailType, SectionTreeType } from '../Home/propTypes';

const Wrapper = styled.div``;

const MenuWrapper = styled.div`
  width: 100%;
  position: fixed;
  padding: 0 ${rem(15)} ${rem(30)} ${rem(15)};
  z-index: 99;
  top: ${rem(108)};
  bottom: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: ${theme.secondary};
`;
const Spacer = styled.div`
  width: 100%;
  height: ${rem(108)};
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.onMenuClickHandle = this.onMenuClickHandle.bind(this);

    this.state = {
      visible: false,
      windowHeight: '',
    };
  }

  onMenuClickHandle() {
    const clicked = this.state.visible;
    this.setState({ visible: !clicked });
  }

  render() {
    const { location } = this.props;
    const pathname = location && location.pathname;

    return (
      <Wrapper>
        <Navbar
          onMenuClick={this.onMenuClickHandle}
          visible={this.state.visible}
          path={pathname}
          pathname={pathname}
        />
        {this.state.visible && pathname !== '/' && <Spacer />}
        <Motion
          defaultStyle={{ x: +100, opacity: 0.9 }}
          style={{
            x: spring(this.state.visible ? 0 : 100, {
              stiffness: 120,
              damping: 19,
            }),
            opacity: spring(this.state.visible ? 1 : 0.6),
          }}
        >
          {style => (
            <MenuWrapper
              pathname={pathname}
              windowHeight={this.state.windowHeight}
              style={{
                transform: `translateY(${style.x}vh)`,
                opacity: style.opacity,
              }}
            >
              <Navigation
                phone={this.props.phone}
                email={this.props.email}
                visible={this.state.visible}
                onClickLink={this.onMenuClickHandle}
                sectionTree={this.props.sectionsTree}
              />
            </MenuWrapper>
          )}
        </Motion>
      </Wrapper>
    );
  }
}

Header.propTypes = {
  email: EmailType,
  phone: PhoneType,
  sectionsTree: SectionTreeType.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

const mapStateToProps = state => {
  return {
    sectionsTree: sectionTreeSelector(state),
  };
};

export default connect(mapStateToProps)(Header);
