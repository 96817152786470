import {
  REQUEST_ARTICLES,
  RECEIVE_ARTICLES_SUCCESS,
  RECEIVE_ARTICLES_ERROR,
  REQUEST_SECTION_TREE,
  RECEIVE_SECTION_TREE_SUCCESS,
  RECEIVE_SECTION_TREE_ERROR,
  REQUEST_SECTION,
  RECEIVE_SECTION_SUCCESS,
  RECEIVE_SECTION_ERROR,
} from './constants';

// Section ARTICLES
const articlesBySmartTagReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ARTICLES:
      return {
        ...state,
        [action.payload.smartTag]: {
          ...state[action.payload.smartTag],
          isFetching: true,
        },
      };
    case RECEIVE_ARTICLES_SUCCESS:
      return {
        ...state,
        [action.payload.smartTag]: {
          ...state[action.payload.smartTag],
          articles: action.payload.articleIds,
          isFetching: false,
          error: null,
        },
      };
    case RECEIVE_ARTICLES_ERROR:
      return {
        ...state,
        [action.payload.smartTag]: {
          ...state[action.payload.smartTag],
          isFetching: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};

const sectionTreesByUniqIdReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_SECTION_TREE:
      return {
        ...state,
        [action.payload.uniqId]: {
          ...state[action.payload.uniqId],
          isFetching: true,
        },
      };
    case RECEIVE_SECTION_TREE_SUCCESS:
      return {
        ...state,
        [action.payload.uniqId]: {
          ...state[action.payload.uniqId],
          section: action.payload.section,
          tree: action.payload.tree,
          isFetching: false,
          error: null,
        },
      };
    case RECEIVE_SECTION_TREE_ERROR:
      return {
        ...state,
        [action.payload.uniqId]: {
          ...state[action.payload.uniqId],
          isFetching: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};

const sectionsByUniqIdReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_SECTION:
      return {
        ...state,
        [action.payload.uniqId]: {
          ...state[action.payload.uniqId],
          isFetching: true,
        },
      };
    case RECEIVE_SECTION_SUCCESS:
      return {
        ...state,
        [action.payload.uniqId]: {
          ...state[action.payload.uniqId],
          sectionId: action.payload.sectionId,
          isFetching: false,
          error: null,
        },
      };
    case RECEIVE_SECTION_ERROR:
      return {
        ...state,
        [action.payload.uniqId]: {
          ...state[action.payload.uniqId],
          isFetching: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};

export {
  articlesBySmartTagReducer,
  sectionTreesByUniqIdReducer,
  sectionsByUniqIdReducer,
};
