import { css } from 'styled-components';
import { rem } from 'polished';
import { getTheme } from '@sportnet/ui/lib/Themes/baseTheme';

export const sizes = {
  lg: 1200,
  l: 1100,
  md: 992,
  m: 900,
  sm: 768,
  xsm: 700,
  s: 450,
  xs: 350,
};

export const media = {
  xs: (...args) => css`
    @media (max-width: ${sizes.xs}px) {
      ${css(...args)};
    }
  `,
  s: (...args) => css`
    @media (max-width: ${sizes.s}px) {
      ${css(...args)};
    }
  `,
  xsm: (...args) => css`
    @media (max-width: ${sizes.xsm}px) {
      ${css(...args)};
    }
  `,
  sm: (...args) => css`
    @media (max-width: ${sizes.sm}px) {
      ${css(...args)};
    }
  `,
  m: (...args) => css`
    @media (max-width: ${sizes.m}px) {
      ${css(...args)};
    }
  `,
  md: (...args) => css`
    @media (max-width: ${sizes.md}px) {
      ${css(...args)};
    }
  `,
  l: (...args) => css`
    @media (max-width: ${sizes.l}px) {
      ${css(...args)};
    }
  `,
  lg: (...args) => css`
    @media (max-width: ${sizes.lg}px) {
      ${css(...args)};
    }
  `,
};

const baseTheme = getTheme({
  color: {
    primary: '#f92539',
  },
});

export const theme = {
  ...baseTheme,
  primary: '#f92539',
  secondary: '#333',
  successColor: '#87D37C',
  dangerColor: '#D24D57',
  primaryFont: `Open Sans, sans-serif`,
  secondaryFont: `Montserrat, sans-serif`,
  lgTitle: rem(40),
  mdTitle: rem(32),
  smTitle: rem(24),
  borderRadius: rem(4),
  media,
  grid: {
    gutterWidth: 10,
  },
  app: {
    maxWidth: 1200,
    padding: 0,
    bgColor: '#fff',
    secondaryBgColor: '#FAFAFA',
    textColor: '#484848',
    secondaryTextColor: '#707070',
  },
};
