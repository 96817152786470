import { createSelector } from 'reselect';
import { getProp } from '../../utilities';

// Content space
export const contentSpaceSelector = () => 'sportnet';

// Entity
export const entitiesDomainSelector = state => state.entities;

export const entitiesTypeSelector = type =>
  createSelector(
    entitiesDomainSelector,
    substate => getProp(substate, type, {}),
  );

export const entitySelector = (type, id) =>
  createSelector(
    entitiesTypeSelector(type),
    byId => getProp(byId, id, {}),
  );

// Path
export const pathBySectionIdDomainSelector = state => state.pathBySectionId;

export const pathSelector = id =>
  createSelector(
    pathBySectionIdDomainSelector,
    bySectionId => getProp(bySectionId, `${id}.path`, []),
  );

// Section Tree
const sectionTreeDomainSelector = state => state.sectionTree.tree;

const processSectionTree = sections => {
  return sections.map(section => {
    return {
      ...section,
      _id: String(section._id),
      title: section.name,
      children: processSectionTree(section.sections),
    };
  });
};

export const sectionTreeSelector = createSelector(
  sectionTreeDomainSelector,
  sections => {
    return processSectionTree(sections);
  },
);
// Static texts

const staticTextsDomainSelector = state => state.staticTextsByCid;

const staticTextObjectSelector = cid =>
  createSelector(
    staticTextsDomainSelector,
    byCid => getProp(byCid, cid, {}),
  );

export const isFetchinStaticTextSelector = cid =>
  createSelector(
    staticTextObjectSelector(cid),
    staticTextObject => getProp(staticTextObject, 'isFetching', false),
  );
