/* eslint-disable */
import 'isomorphic-fetch';

class CsmApi {
  constructor() {
    this.baseUrl = null;
    this.token = null;
  }

  serializeQueryParams(parameters) {
    return Object.keys(parameters)
      .reduce((acc, p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  paramsToObject(params) {
    let query = params.substr(1);
    let result = {};
    query.split('&').forEach(function(part) {
      let item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        const parameter = parameters.$queryParameters[parameterName];
        queryParameters[parameterName] = parameter;
      });
    }
    return queryParameters;
  }

  transformParameter(value, transformOperation = null) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  /**
   * HTTP Request
   * @method
   * @param {string} method - http method
   * @param {string} url - url to do request
   * @param {object} body - body parameters / object
   * @param {object} headers - header parameters
   * @param {object} queryParameters - querystring parameters
   */
  request(
    method,
    url,
    body,
    headers,
    queryParameters,
    form,
    checkFor401 = true,
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    // ugly hack, we need to delete Content-Type header with multipart/form-data
    // that way, browser will calculate form specific headers on it's own
    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (body && !Object.keys(body).length) {
      body = undefined;
    } else {
      body = JSON.stringify(body);
    }

    if (form && Object.keys(form).length) {
      body = new FormData();
      for (let k in form) {
        body.append(k, form[k]);
      }
    }

    return fetch(urlWithParams, {
      method,
      headers,
      body,
    })
      .then(response => {
        if (checkFor401) {
          if (response.status === 401) {
            if (typeof this._onResponseUnauthorized === 'function') {
              this._onResponseUnauthorized();
            } else {
              let error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          }
        }

        if (response.ok) {
          if (
            response.headers.get('Content-Type').includes('application/json')
          ) {
            return response.json();
          } else if (
            response.headers.get('Content-Type').includes('application/pdf')
          ) {
            return response.blob();
          }
          return {};
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .catch(error => {
        return error.response.json().then(error_details => {
          error.details = error_details;
          throw error;
        });
      });
  }

  /**
   * Set base url
   * @method
   * @param {string} baseUrl
   */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  set onResponseUnauthorized(callback) {
    this._onResponseUnauthorized = callback;
  }

  /**
   * Redirects a user to a given url
   * @method
   * @param {string} url
   */
  redirect(url) {
    window.location = url;
  }

  /**
   * Set Token
   * @method
   * @param {string} token - token's value
   */
  setToken(token) {
    this.token = token;
  }

  /**
   * Set Auth headers
   * @method
   * @param {object} headerParams - headers object
   */
  appendAuthHeaders(headerParams) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  /**
   * Get Contentspace Details
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   */
  getContentspace(contentspace, parameters = {}) {
    let path = '/admin/{contentspace}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Edit Contentspace Settings or/and Doctypes
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   */
  editContentspace(contentspace, parameters = {}) {
    let path = '/admin/{contentspace}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{contentspace}', contentspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get Doctypes for Contentspace
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   */
  getDoctypes(contentspace, parameters = {}) {
    let path = '/admin/{contentspace}/doctypes';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Edit Contentspace Doctypes
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   */
  editDoctypes(contentspace, parameters = {}) {
    let path = '/admin/{contentspace}/doctypes';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{contentspace}', contentspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete Contentspace Doctype
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {string} doctypeId -
   */
  deleteDoctype(contentspace, doctypeId, parameters = {}) {
    let path = '/admin/{contentspace}/doctypes/{doctype_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{doctype_id}', doctypeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get User By Tokeninfo
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   */
  getUserByTokeninfo(contentspace, parameters = {}) {
    let path = '/admin/{contentspace}/user';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Returns List of Articles with possibility to offset and limit results. Also possible to filter results by smarttags and doctype.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {number} parameters.offset -
   * @param {number} parameters.limit -
   * @param {array} parameters.doctype -
   * @param {array} parameters.smarttags -
   * @param {integer} parameters.sectionId - ID sekcie
   * @param {string} parameters.authorId - ID autora clanku (kto vytvoril)
   * @param {string} parameters.q - Fulltext ($text index v kolekcii)
   * @param {string} parameters.sorter - Sorter
   * @param {string} contentspace -
   */
  getArticles(contentspace, parameters = {}) {
    let path = '/admin/{contentspace}/article';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 50;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['doctype'] = [];

    if (typeof parameters['doctype'] !== 'undefined') {
      queryParameters['doctype'] = parameters['doctype'];
    }

    queryParameters['doctype'] = this.transformParameter(
      queryParameters['doctype'],
      'joinUsingPipes',
    );

    queryParameters['smarttags'] = [];

    if (typeof parameters['smarttags'] !== 'undefined') {
      queryParameters['smarttags'] = parameters['smarttags'];
    }

    queryParameters['smarttags'] = this.transformParameter(
      queryParameters['smarttags'],
      'joinUsingPipes',
    );

    if (typeof parameters['sectionId'] !== 'undefined') {
      queryParameters['sectionId'] = parameters['sectionId'];
    }

    queryParameters['sectionId'] = this.transformParameter(
      queryParameters['sectionId'],
    );

    if (typeof parameters['authorId'] !== 'undefined') {
      queryParameters['authorId'] = parameters['authorId'];
    }

    queryParameters['authorId'] = this.transformParameter(
      queryParameters['authorId'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['sorter'] = '-created';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    path = path.replace('{contentspace}', contentspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Insert New Article with Given Properties
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   */
  postArticle(contentspace, parameters = {}) {
    let path = '/admin/{contentspace}/article';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{contentspace}', contentspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Returns Article By ID
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {integer} articleId -
   */
  getArticle(contentspace, articleId, parameters = {}) {
    let path = '/admin/{contentspace}/article/{article_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{article_id}', articleId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Edit Article By ID
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   * @param {integer} articleId -
   */
  editArticle(contentspace, articleId, parameters = {}) {
    let path = '/admin/{contentspace}/article/{article_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{article_id}', articleId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete Article By ID
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {integer} articleId -
   */
  deleteArticle(contentspace, articleId, parameters = {}) {
    let path = '/admin/{contentspace}/article/{article_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{article_id}', articleId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Returns List of Sections
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   */
  getSections(contentspace, parameters = {}) {
    let path = '/admin/{contentspace}/section';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Insert New Section with Given Properties
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   */
  postSection(contentspace, parameters = {}) {
    let path = '/admin/{contentspace}/section';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{contentspace}', contentspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Returns Detail of Section
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {integer} sectionId -
   */
  getSection(contentspace, sectionId, parameters = {}) {
    let path = '/admin/{contentspace}/section/{section_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_id}', sectionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Insert New Section with Given Properties As Subsection to Parent ID
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   * @param {integer} sectionId -
   */
  postSubsection(contentspace, sectionId, parameters = {}) {
    let path = '/admin/{contentspace}/section/{section_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_id}', sectionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Edit Section
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   * @param {integer} sectionId -
   */
  editSection(contentspace, sectionId, parameters = {}) {
    let path = '/admin/{contentspace}/section/{section_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_id}', sectionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete Section
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {integer} sectionId -
   */
  deleteSection(contentspace, sectionId, parameters = {}) {
    let path = '/admin/{contentspace}/section/{section_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_id}', sectionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get List of Users With Allowed Access For Given Section Id
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {integer} sectionId -
   */
  getSectionAllowedUsers(contentspace, sectionId, parameters = {}) {
    let path = '/admin/{contentspace}/section/{section_id}/allowed-users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_id}', sectionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Grant Access For Given User and Section Id
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   * @param {integer} sectionId -
   * @param {string} userId -
   */
  grantSectionAccess(contentspace, sectionId, userId, parameters = {}) {
    let path =
      '/admin/{contentspace}/section/{section_id}/allowed-users/{user_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_id}', sectionId);

    path = path.replace('{user_id}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Remove Access For Given User and Section Id
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {integer} sectionId -
   * @param {string} userId -
   */
  removeSectionAccess(contentspace, sectionId, userId, parameters = {}) {
    let path =
      '/admin/{contentspace}/section/{section_id}/allowed-users/{user_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_id}', sectionId);

    path = path.replace('{user_id}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get List of Subsections for Given Parent Section ID
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {integer} sectionId -
   */
  getSubsections(contentspace, sectionId, parameters = {}) {
    let path = '/admin/{contentspace}/section/{section_id}/sections';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_id}', sectionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Fetch Admin Sections Tree
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   */
  getAdminSectionsTree(contentspace, parameters = {}) {
    let path = '/admin/{contentspace}/sections-tree';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get Detail of Section Content
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {integer} sectionId -
   */
  getSectionContent(contentspace, sectionId, parameters = {}) {
    let path = '/admin/{contentspace}/section/{section_id}/content';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_id}', sectionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Create Section Content
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   * @param {integer} sectionId -
   */
  postSectionContent(contentspace, sectionId, parameters = {}) {
    let path = '/admin/{contentspace}/section/{section_id}/content';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_id}', sectionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Edit Section Content
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   * @param {integer} sectionId -
   */
  editSectionContent(contentspace, sectionId, parameters = {}) {
    let path = '/admin/{contentspace}/section/{section_id}/content';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_id}', sectionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete Section Content
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {integer} sectionId -
   */
  deleteSectionContent(contentspace, sectionId, parameters = {}) {
    let path = '/admin/{contentspace}/section/{section_id}/content';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_id}', sectionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Provide List of Articles By Section Id
   * @method
   * @param {object} parameters - method options and parameters
   * @param {number} parameters.offset -
   * @param {number} parameters.limit -
   * @param {array} parameters.doctype -
   * @param {array} parameters.smarttags -
   * @param {string} parameters.authorId - ID autora clanku (kto vytvoril)
   * @param {string} parameters.q - Fulltext ($text index v kolekcii)
   * @param {string} parameters.sorter - Sorter
   * @param {string} contentspace -
   * @param {integer} sectionId -
   */
  getArticlesBySectionId(contentspace, sectionId, parameters = {}) {
    let path = '/admin/{contentspace}/section/{section_id}/articles';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 50;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['doctype'] = [];

    if (typeof parameters['doctype'] !== 'undefined') {
      queryParameters['doctype'] = parameters['doctype'];
    }

    queryParameters['doctype'] = this.transformParameter(
      queryParameters['doctype'],
      'joinUsingPipes',
    );

    queryParameters['smarttags'] = [];

    if (typeof parameters['smarttags'] !== 'undefined') {
      queryParameters['smarttags'] = parameters['smarttags'];
    }

    queryParameters['smarttags'] = this.transformParameter(
      queryParameters['smarttags'],
      'joinUsingPipes',
    );

    if (typeof parameters['authorId'] !== 'undefined') {
      queryParameters['authorId'] = parameters['authorId'];
    }

    queryParameters['authorId'] = this.transformParameter(
      queryParameters['authorId'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters['sorter'] = '-created';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_id}', sectionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Add Article For Provided Section Id
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   * @param {integer} sectionId -
   */
  postArticleBySectionId(contentspace, sectionId, parameters = {}) {
    let path = '/admin/{contentspace}/section/{section_id}/articles';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_id}', sectionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Change Ordering of Sections In Current Level
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   */
  changeOrderOfSections(contentspace, parameters = {}) {
    let path = '/admin/{contentspace}/sections/order';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{contentspace}', contentspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Provide List of Static Contents
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   */
  getStaticContents(contentspace, parameters = {}) {
    let path = '/admin/{contentspace}/static-content';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Provide Detail of Static Content
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {string} cid -
   */
  getStaticContent(contentspace, cid, parameters = {}) {
    let path = '/admin/{contentspace}/static-content/{cid}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{cid}', cid);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Insert New Static Content
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   * @param {string} cid -
   */
  postStaticContent(contentspace, cid, parameters = {}) {
    let path = '/admin/{contentspace}/static-content/{cid}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{cid}', cid);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Edit Static Content
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   * @param {string} cid -
   */
  editStaticContent(contentspace, cid, parameters = {}) {
    let path = '/admin/{contentspace}/static-content/{cid}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{cid}', cid);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete Static Content
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {string} cid -
   */
  deleteStaticContent(contentspace, cid, parameters = {}) {
    let path = '/admin/{contentspace}/static-content/{cid}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{cid}', cid);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Provide Article for Static Content
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {string} cid -
   */
  getArticleByStaticContentId(contentspace, cid, parameters = {}) {
    let path = '/admin/{contentspace}/static-content/{cid}/content';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{cid}', cid);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Insert Content For Static Content
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   * @param {string} cid -
   */
  postArticleForStaticContent(contentspace, cid, parameters = {}) {
    let path = '/admin/{contentspace}/static-content/{cid}/content';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{cid}', cid);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Edit Content For Static Content
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   * @param {string} cid -
   */
  editArticleForStaticContent(contentspace, cid, parameters = {}) {
    let path = '/admin/{contentspace}/static-content/{cid}/content';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{cid}', cid);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete Content For Static Content
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {string} cid -
   */
  deleteArticleForStaticContent(contentspace, cid, parameters = {}) {
    let path = '/admin/{contentspace}/static-content/{cid}/content';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{cid}', cid);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Fetch Settings for Given Contentspace
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   */
  getSettings(contentspace, parameters = {}) {
    let path = '/public/{contentspace}/settings';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Sends a mail from any contact form using data from app space settings
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   */
  webContactFormRequest(contentspace, parameters = {}) {
    let path = '/public/{contentspace}/request/web-contact-form';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{contentspace}', contentspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} contentspace -
   */
  expandWidgets(contentspace, parameters = {}) {
    let path = '/public/{contentspace}/expand-widgets';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{contentspace}', contentspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Fetch URL map for Given URL
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {string} parameters.urlPath -
   * @param {number} parameters.expandObject -
   * @param {boolean} parameters.expandWidgets - Tells wheter widgets in content should be expanded or not
   */
  getContentByUrl(contentspace, parameters = {}) {
    let path = '/public/{contentspace}/urlmap';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    if (typeof parameters['urlPath'] !== 'undefined') {
      queryParameters['url_path'] = parameters['urlPath'];
    }

    queryParameters['url_path'] = this.transformParameter(
      queryParameters['url_path'],
    );

    if (typeof parameters['expandObject'] !== 'undefined') {
      queryParameters['expand_object'] = parameters['expandObject'];
    }

    queryParameters['expand_object'] = this.transformParameter(
      queryParameters['expand_object'],
    );

    queryParameters['expand_widgets'] = false;

    if (typeof parameters['expandWidgets'] !== 'undefined') {
      queryParameters['expand_widgets'] = parameters['expandWidgets'];
    }

    queryParameters['expand_widgets'] = this.transformParameter(
      queryParameters['expand_widgets'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Fetch URL map for Given URL
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {string} urlPath -
   * @param {number} parameters.expandObject -
   * @param {boolean} parameters.expandWidgets - Tells wheter widgets in content should be expanded or not
   */
  getContentByUrl_1(contentspace, urlPath, parameters = {}) {
    let path = '/public/{contentspace}/urlmap/{url_path}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{url_path}', urlPath);

    if (typeof parameters['expandObject'] !== 'undefined') {
      queryParameters['expand_object'] = parameters['expandObject'];
    }

    queryParameters['expand_object'] = this.transformParameter(
      queryParameters['expand_object'],
    );

    queryParameters['expand_widgets'] = false;

    if (typeof parameters['expandWidgets'] !== 'undefined') {
      queryParameters['expand_widgets'] = parameters['expandWidgets'];
    }

    queryParameters['expand_widgets'] = this.transformParameter(
      queryParameters['expand_widgets'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Fetch Content of Sections By Widget Type
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {string} widgetType -
   */
  getSectionContentByWidgetType(contentspace, widgetType, parameters = {}) {
    let path = '/public/{contentspace}/sectiondescr-widgets/{widget_type}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{widget_type}', widgetType);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Fetch Public Articles
   * @method
   * @param {object} parameters - method options and parameters
   * @param {number} parameters.offset -
   * @param {number} parameters.limit -
   * @param {array} parameters.tag -
   * @param {array} parameters.widgets -
   * @param {array} parameters.widgetsType -
   * @param {array} parameters.excludetags -
   * @param {array} parameters.smarttags -
   * @param {array} parameters.doctypes -
   * @param {string} parameters.q -
   * @param {string} parameters.sorter -
   * @param {string} parameters.excludeIds - Ciarkou oddelene idecka
   * @param {number} parameters.sectionId -
   * @param {array} contentspaceList -
   */
  getPublicArticles(contentspaceList, parameters = {}) {
    let path = '/public/{contentspace_list}/articles';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 50;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['tag'] !== 'undefined') {
      queryParameters['tag'] = parameters['tag'];
    }

    queryParameters['tag'] = this.transformParameter(queryParameters['tag']);

    if (typeof parameters['widgets'] !== 'undefined') {
      queryParameters['widgets'] = parameters['widgets'];
    }

    queryParameters['widgets'] = this.transformParameter(
      queryParameters['widgets'],
    );

    if (typeof parameters['widgetsType'] !== 'undefined') {
      queryParameters['widgets_type'] = parameters['widgetsType'];
    }

    queryParameters['widgets_type'] = this.transformParameter(
      queryParameters['widgets_type'],
    );

    if (typeof parameters['excludetags'] !== 'undefined') {
      queryParameters['excludetags'] = parameters['excludetags'];
    }

    queryParameters['excludetags'] = this.transformParameter(
      queryParameters['excludetags'],
    );

    if (typeof parameters['smarttags'] !== 'undefined') {
      queryParameters['smarttags'] = parameters['smarttags'];
    }

    queryParameters['smarttags'] = this.transformParameter(
      queryParameters['smarttags'],
      'joinUsingPipes',
    );

    if (typeof parameters['doctypes'] !== 'undefined') {
      queryParameters['doctypes'] = parameters['doctypes'];
    }

    queryParameters['doctypes'] = this.transformParameter(
      queryParameters['doctypes'],
      'joinUsingPipes',
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['excludeIds'] !== 'undefined') {
      queryParameters['exclude_ids'] = parameters['excludeIds'];
    }

    queryParameters['exclude_ids'] = this.transformParameter(
      queryParameters['exclude_ids'],
    );

    if (typeof parameters['sectionId'] !== 'undefined') {
      queryParameters['section_id'] = parameters['sectionId'];
    }

    queryParameters['section_id'] = this.transformParameter(
      queryParameters['section_id'],
    );

    path = path.replace('{contentspace_list}', contentspaceList);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Fetch Public Article By Slug
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {string} articleIdOrSlug -
   * @param {boolean} parameters.expandWidgets - Tells wheter widgets in content should be expanded or not
   */
  getPublicArticlesByIdOrSlug(contentspace, articleIdOrSlug, parameters = {}) {
    let path = '/public/{contentspace}/articles/{article_idOrSlug}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{article_idOrSlug}', articleIdOrSlug);

    queryParameters['expand_widgets'] = false;

    if (typeof parameters['expandWidgets'] !== 'undefined') {
      queryParameters['expand_widgets'] = parameters['expandWidgets'];
    }

    queryParameters['expand_widgets'] = this.transformParameter(
      queryParameters['expand_widgets'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Edit Stats of Public Article By Slug
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {string} articleIdOrSlug -
   */
  putArticleStats(contentspace, articleIdOrSlug, parameters = {}) {
    let path = '/public/{contentspace}/articlestats/{article_idOrSlug}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{article_idOrSlug}', articleIdOrSlug);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Fetch Public Section By Id or Slug
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {string} sectionIdOrUniqId -
   * @param {boolean} parameters.expandWidgets - Tells wheter widgets in content should be expanded or not
   */
  getPublicSectionByIdOrUniqId(
    contentspace,
    sectionIdOrUniqId,
    parameters = {},
  ) {
    let path = '/public/{contentspace}/sections/{section_idOrUniqId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_idOrUniqId}', sectionIdOrUniqId);

    queryParameters['expand_widgets'] = false;

    if (typeof parameters['expandWidgets'] !== 'undefined') {
      queryParameters['expand_widgets'] = parameters['expandWidgets'];
    }

    queryParameters['expand_widgets'] = this.transformParameter(
      queryParameters['expand_widgets'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Fetch Public Sections Tree
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   */
  getPublicSectionsTree(contentspace, parameters = {}) {
    let path = '/public/{contentspace}/sections-tree';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Fetch Public Section Tree By Id or Uniqid
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {string} sectionIdOrUniqId -
   */
  getPublicSectionTreeByIdOrUniqId(
    contentspace,
    sectionIdOrUniqId,
    parameters = {},
  ) {
    let path = '/public/{contentspace}/sections/{section_idOrUniqId}/tree';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_idOrUniqId}', sectionIdOrUniqId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Fetch Public Subsections By Id
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {string} sectionIdOrUniqId -
   * @param {number} parameters.treelevel -
   */
  getPublicSubsectionsByIdOrUniqId(
    contentspace,
    sectionIdOrUniqId,
    parameters = {},
  ) {
    let path = '/public/{contentspace}/sections/{section_idOrUniqId}/sections';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_idOrUniqId}', sectionIdOrUniqId);

    queryParameters['treelevel'] = 0;

    if (typeof parameters['treelevel'] !== 'undefined') {
      queryParameters['treelevel'] = parameters['treelevel'];
    }

    queryParameters['treelevel'] = this.transformParameter(
      queryParameters['treelevel'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Fetch Public Articles By Section Id
   * @method
   * @param {object} parameters - method options and parameters
   * @param {number} parameters.offset -
   * @param {number} parameters.limit -
   * @param {boolean} parameters.includeNested -
   * @param {number} parameters.excludeId -
   * @param {string} parameters.excludeIds -
   * @param {array} parameters.widgets -
   * @param {array} parameters.widgetsType -
   * @param {string} contentspace -
   * @param {integer} sectionId -
   */
  getPublicArticlesBySectionId(contentspace, sectionId, parameters = {}) {
    let path = '/public/{contentspace}/sections/{section_id}/articles';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 50;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['include_nested'] = false;

    if (typeof parameters['includeNested'] !== 'undefined') {
      queryParameters['include_nested'] = parameters['includeNested'];
    }

    queryParameters['include_nested'] = this.transformParameter(
      queryParameters['include_nested'],
    );

    if (typeof parameters['excludeId'] !== 'undefined') {
      queryParameters['exclude_id'] = parameters['excludeId'];
    }

    queryParameters['exclude_id'] = this.transformParameter(
      queryParameters['exclude_id'],
    );

    if (typeof parameters['excludeIds'] !== 'undefined') {
      queryParameters['exclude_ids'] = parameters['excludeIds'];
    }

    queryParameters['exclude_ids'] = this.transformParameter(
      queryParameters['exclude_ids'],
    );

    if (typeof parameters['widgets'] !== 'undefined') {
      queryParameters['widgets'] = parameters['widgets'];
    }

    queryParameters['widgets'] = this.transformParameter(
      queryParameters['widgets'],
    );

    if (typeof parameters['widgetsType'] !== 'undefined') {
      queryParameters['widgets_type'] = parameters['widgetsType'];
    }

    queryParameters['widgets_type'] = this.transformParameter(
      queryParameters['widgets_type'],
    );

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_id}', sectionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Fetch Section Path By Section Id
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {integer} sectionId -
   */
  getPathForSectionId(contentspace, sectionId, parameters = {}) {
    let path = '/public/{contentspace}/sections/{section_id}/path';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{section_id}', sectionId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Fetch Static Content By Id
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {string} cid -
   * @param {boolean} parameters.expandWidgets - Tells wheter widgets in content should be expanded or not
   */
  getPublicStaticContentById(contentspace, cid, parameters = {}) {
    let path = '/public/{contentspace}/static-content/{cid}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{cid}', cid);

    queryParameters['expand_widgets'] = false;

    if (typeof parameters['expandWidgets'] !== 'undefined') {
      queryParameters['expand_widgets'] = parameters['expandWidgets'];
    }

    queryParameters['expand_widgets'] = this.transformParameter(
      queryParameters['expand_widgets'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Fetch Static Content By Id
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} contentspace -
   * @param {string} cid -
   * @param {boolean} parameters.expandWidgets - Tells wheter widgets in content should be expanded or not
   */
  getPublicStaticContentById_1(contentspace, cid, parameters = {}) {
    let path = '/public/{contentspace}/static_content/{cid}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{contentspace}', contentspace);

    path = path.replace('{cid}', cid);

    queryParameters['expand_widgets'] = false;

    if (typeof parameters['expandWidgets'] !== 'undefined') {
      queryParameters['expand_widgets'] = parameters['expandWidgets'];
    }

    queryParameters['expand_widgets'] = this.transformParameter(
      queryParameters['expand_widgets'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Gets information from livestream url. Main purpose is for fetching video details and then later embeding it in iframe.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.url -
   */
  parseLivestreamUrl(parameters = {}) {
    let path = '/public/parse-livestream-url';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['url'] !== 'undefined') {
      queryParameters['url'] = parameters['url'];
    }

    queryParameters['url'] = this.transformParameter(queryParameters['url']);

    if (typeof parameters['url'] === 'undefined') {
      throw Error('Missing required parameter: url');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
}

export default new CsmApi();

