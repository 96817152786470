import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { theme } from '../../theme';
import { connect } from 'react-redux';
import { sectionTreeSelector } from '../App/selectors';
import { Link } from 'react-router';
import ArrowImg from '../../assets/images/bullet-arrow.svg';
import FooterTop from '../../components/Footer/FooterTop';
import SectionName from '../../components/SectionName';
import FooterMiddle from '../../components/Footer/FooterMiddle';
import FooterBottom from '../../components/Footer/FooterBottom';
import { getSectionUrl } from '../../utilities';

const Wrapper = styled.div`
  width: 100%;
  background: ${theme.secondary};
  flex-wrap: wrap;

  & > ul > ul > li > a {
    margin-bottom: ${rem(10)};
  }
`;

const MenuListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  width: 25%;
  flex: 1 1 auto;
  margin-bottom: ${rem(15)};

  & > div {
    width: 100%;
  }

  ${({ theme }) => theme.media.m`
    width: 50%;
  `};

  ${({ theme }) => theme.media.xsm`
    width: 100%;
  `};
`;

const RouterLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const MenuList = styled.li`
  color: #fff;
  line-height: 1.5;
  position: relative;

  ${({ theme }) => theme.media.xsm`
  text-align: center;
  font-size: ${rem(16)};
  margin-bottom: ${rem(10)};
  `};
`;

const Arrow = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${rem(-15)};

  ${({ theme }) => theme.media.xsm`
    display: none;
  `};
`;

class Footer extends Component {
  render() {
    const sections = this.props.sectionsTree.map(section => {
      const subSections =
        section.sections &&
        section.sections.length > 0 &&
        section.sections.map(subSection => {
          return (
            <MenuList key={subSection._id}>
              <Arrow src={ArrowImg} alt={subSection.name} />
              <RouterLink to={getSectionUrl(subSection)}>
                <SectionName section={subSection} />
              </RouterLink>
            </MenuList>
          );
        });
      return (
        <MenuListWrapper key={section._id}>
          <MenuList>
            <RouterLink to={getSectionUrl(section)}>
              <SectionName section={section} />
            </RouterLink>{' '}
          </MenuList>
          <div>{subSections}</div>
        </MenuListWrapper>
      );
    });
    return (
      <Wrapper>
        <FooterTop sections={sections} />
        <FooterMiddle phone={this.props.phone} email={this.props.email} />
        <FooterBottom />
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    sectionsTree: sectionTreeSelector(state),
  };
};

Footer.propTypes = {
  email: PropTypes.shape({
    cid: PropTypes.string,
    content: PropTypes.shape({
      widgets: PropTypes.arrayOf(
        PropTypes.shape({
          header: PropTypes.string,
          text: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
    }),
  }),
  phone: PropTypes.shape({
    cid: PropTypes.string,
    content: PropTypes.shape({
      widgets: PropTypes.arrayOf(
        PropTypes.shape({
          header: PropTypes.string,
          text: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
    }),
  }),
  sectionsTree: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
};

export default connect(mapStateToProps)(Footer);
