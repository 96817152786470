import CsmApi from '../../CsmApi';
import {
  REQUEST_SECTION,
  RECEIVE_SECTION_SUCCESS,
  RECEIVE_SECTION_ERROR,
  REQUEST_SECTION_ARTICLES,
  RECEIVE_SECTION_ARTICLES_SUCCESS,
  RECEIVE_SECTION_ARTICLES_ERROR,
  UPDATE_SECTION,
  SET_CURRENT_SECTION_ID,
} from './constants';
import { normalize } from 'normalizr';
import { sectionSchema, articleSchema } from '../../schemas';
import { contentSpaceSelector } from '../App/selectors';
import { NotFoundError } from '../../errors';

// Section CONTENT

export const requestSection = id => ({
  type: REQUEST_SECTION,
  payload: {
    id,
  },
});

export const receiveSectionSuccess = (id, entities) => ({
  type: RECEIVE_SECTION_SUCCESS,
  payload: {
    id,
    entities,
  },
});

export const updateSection = (id, entities) => ({
  type: UPDATE_SECTION,
  payload: {
    id,
    entities,
  },
});

export const receiveSectionError = (id, error) => ({
  type: RECEIVE_SECTION_ERROR,
  payload: {
    id,
    error,
  },
});

export const loadSection = id => {
  return (dispatch, getState) => {
    dispatch(requestSection(id));
    const contentSpace = contentSpaceSelector(getState());
    return CsmApi.getPublicSectionByIdOrUniqId(contentSpace, id)
      .then(data => {
        const normalized = normalize(
          { ...data.section, content: data.content || {} },
          sectionSchema,
        );
        dispatch(receiveSectionSuccess(normalized.result, normalized.entities));
      })
      .catch(error => {
        dispatch(receiveSectionError(id, error));
        throw new NotFoundError();
      });
  };
};

// Section ARTICLES

export const requestSectionArticles = id => ({
  type: REQUEST_SECTION_ARTICLES,
  payload: {
    id,
  },
});

export const receiveSectionArticlesSuccess = (id, articleIds, entities) => ({
  type: RECEIVE_SECTION_ARTICLES_SUCCESS,
  payload: {
    id,
    articleIds,
    entities,
  },
});

export const receiveSectionArticlesError = (id, error) => ({
  type: RECEIVE_SECTION_ARTICLES_ERROR,
  payload: {
    id,
    error,
  },
});

export const loadSectionArticles = id => {
  return (dispatch, getState) => {
    dispatch(requestSectionArticles(id));
    const contentSpace = contentSpaceSelector(getState());
    return CsmApi.getPublicArticlesBySectionId(contentSpace, id)
      .then(data => {
        const normalized = normalize(data.articles, [articleSchema]);
        dispatch(
          receiveSectionArticlesSuccess(
            id,
            normalized.result,
            normalized.entities,
          ),
        );
      })
      .catch(error => {
        dispatch(receiveSectionArticlesError(id, error));
      });
  };
};

// Set current section id

export const setCurrentSectionId = id => ({
  type: SET_CURRENT_SECTION_ID,
  payload: {
    id,
  },
});
