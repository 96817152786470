import React from 'react';
import PropTypes from 'prop-types';
import { IndexRoute, Router, Route } from 'react-router';

// containers
import App from './containers/App';
import Home from './containers/Home';
import Page from './containers/Page';
import Article from './containers/Article';
import Section from './containers/Section';
import UrlMap from './containers/UrlMap';

const Routes = props => {
  return (
    <Router {...props}>
      <Route
        path="/"
        component={App}
        onChange={(prevState, nextState) => {
          if (nextState.location.action !== 'POP') {
            window.scroll({ left: 0, top: 0, behavior: `smooth` });
          }
        }}
      >
        <IndexRoute component={Home} />
        <Route component={Page}>
          <Route path="article/:id" component={Article} />
          <Route path="section/:id" component={Section} />
          <Route path="*" component={UrlMap} />
        </Route>
      </Route>
    </Router>
  );
};

Routes.propTypes = {
  store: PropTypes.object,
};

export default Routes;
