import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme } from '../../theme';
import { getBannerPictureUri, getProp } from '../../utilities';

const Wrapper = styled.div`
  padding: 0 ${rem(15)};
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: ${rem(1200)};
  margin: 0 auto;
  padding: ${rem(30)} 0;
  justify-content: center;

  @media all and (max-width: ${rem(700)}) {
    & > div:first-child {
      margin-bottom: ${rem(15)};
    }
  }

  ${({ theme }) => theme.media.xsm`
    flex-direction: column;
  `};
`;

export const FooterItem = styled.div`
  margin-left: ${rem(60)};
  display: flex;

  ${({ theme }) => theme.media.xsm`
    margin-left: 0;
    flex-direction: column;
    align-content: center;

    &:first-child {
    margin-left: 0;
    margin-bottom: ${rem(15)};
  }
  `};
`;

export const Icon = styled.img`
  align-self: center;

  ${({ theme }) => theme.media.xsm`
    margin-bottom: ${rem(15)};
  `};
`;

export const ItemText = styled.span`
  font-family: ${theme.secondaryFont};
  font-weight: 600;
  align-self: center;
  margin-left: ${rem(10)};
`;

export const Link = styled.a`
  color: #fff;
  text-decoration: none;
`;

const FooterMiddle = props => {
  const emailWidget = getProp(props.email, 'content', []);
  const phoneWidget = getProp(props.phone, 'content', []);

  const phone = phoneWidget.map(item => {
    return (
      <FooterItem key={item.uri}>
        <Icon src={getBannerPictureUri(item, '0x41')} alt={item.description} />
        <ItemText>
          <Link href={item.target}>{item.description}</Link>
        </ItemText>
      </FooterItem>
    );
  });

  const email = emailWidget.map(item => {
    return (
      <FooterItem key={item.uri}>
        <Icon src={getBannerPictureUri(item, '0x41')} alt={item.description} />
        <ItemText>
          <Link href={item.target}>{item.description}</Link>
        </ItemText>
      </FooterItem>
    );
  });

  return (
    <Wrapper>
      <Footer>
        {phone}
        {email}
      </Footer>
    </Wrapper>
  );
};

FooterMiddle.propTypes = {
  email: PropTypes.shape({
    widgets: PropTypes.arrayOf({
      header: PropTypes.string,
      text: PropTypes.string,
    }),
  }),
  phone: PropTypes.shape({
    widgets: PropTypes.arrayOf({
      header: PropTypes.string,
      text: PropTypes.string,
    }),
  }),
};

export default FooterMiddle;
