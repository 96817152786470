import React from 'react';
import Content from 'content/lib/view';
import { getProp, getArticleUrl, getSectionUrl } from '../../utilities';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { filterToUrl } from 'content/lib/utilities';

const C = ({ widgets }) => (
  <Content
    items={widgets}
    renderLink={link => {
      if (link.type === 'article_detail') {
        return (
          <Link to={getArticleUrl('', link.payload)} {...link.linkProps}>
            {link.linkProps.children}
          </Link>
        );
      } else if (link.type === 'article_filter') {
        const header = link.payload.header;
        const urlSegments = ['filter?'];
        if (header) {
          urlSegments.push(`header=${header}`);
        }
        const filter = getProp(link, ['payload', 'filter'], []);
        if (filter.length > 0) {
          urlSegments.push(filterToUrl(filter.filter(a => a.type !== 'limit')));
        }
        return (
          <Link to={urlSegments.join('&')} {...link.linkProps}>
            {link.linkProps.children}
          </Link>
        );
      } else if (link.type === 'section_detail') {
        return (
          <Link to={getSectionUrl('', link.payload)} {...link.linkProps}>
            {link.linkProps.children}
          </Link>
        );
      }
      return null;
    }}
  />
);

C.propTypes = {
  widgets: PropTypes.arrayOf({}),
};

export default C;
