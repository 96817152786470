import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { loadSectionsTree, loadStaticText, setPathName } from './actions';

const Wrapper = styled.div`
  width: 100%;
`;

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static init(props) {
    const {
      dispatch,
      location: { pathname },
    } = props;
    return Promise.all([
      dispatch(loadSectionsTree()),
      dispatch(loadStaticText('telefon')),
      dispatch(loadStaticText('email')),
      dispatch(setPathName(pathname)),
    ]);
  }

  componentDidMount() {
    App.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const {
      location: { pathname },
    } = this.props;
    const {
      location: { pathname: nextPathname },
    } = nextProps;
    if (pathname !== nextPathname) {
      nextProps.dispatch(setPathName(nextPathname));
    }
  }

  render() {
    const { children } = this.props;
    return <Wrapper>{children}</Wrapper>;
  }
}

App.getInitialProps = props => {
  return App.init(props);
};

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(App);
