import CsmApi from '../../CsmApi';
import {
  REQUEST_ARTICLE,
  RECEIVE_ARTICLE_SUCCESS,
  RECEIVE_ARTICLE_ERROR,
  UPDATE_ARTICLE,
  SET_CURRENT_ARTICLE_ID,
} from './constants';
import { normalize } from 'normalizr';
import { articleSchema } from '../../schemas';
import { contentSpaceSelector } from '../App/selectors';
import { NotFoundError } from '../../errors';

// Article CONTENT

export const requestArticle = id => ({
  type: REQUEST_ARTICLE,
  payload: {
    id,
  },
});

export const receiveArticleSuccess = (id, entities) => ({
  type: RECEIVE_ARTICLE_SUCCESS,
  payload: {
    id,
    entities,
  },
});

export const updateArticle = (id, entities) => ({
  type: UPDATE_ARTICLE,
  payload: {
    id,
    entities,
  },
});

export const receiveArticleError = (id, error) => ({
  type: RECEIVE_ARTICLE_ERROR,
  payload: {
    id,
    error,
  },
});

export const loadArticle = id => {
  return (dispatch, getState) => {
    dispatch(requestArticle(id));
    const contentSpace = contentSpaceSelector(getState());
    return CsmApi.getPublicArticlesByIdOrSlug(contentSpace, id)
      .then(data => {
        const normalized = normalize(data, articleSchema);
        dispatch(receiveArticleSuccess(normalized.result, normalized.entities));
        return data;
      })
      .catch(error => {
        dispatch(receiveArticleError(id, error));
        throw new NotFoundError();
      });
  };
};

// Set current article id

export const setCurrentArticleId = id => ({
  type: SET_CURRENT_ARTICLE_ID,
  payload: {
    id,
  },
});
