import { createSelector } from 'reselect';
import { getProp } from '../../utilities';
import at from 'lodash/at';
import { entitiesTypeSelector } from '../App/selectors';
import { currentArticleIdSelector } from '../Article/selectors';

// Current section id
const currentSectionIdDomainSelector = state => state.currentSectionId;

export const currentSectionIdSelector = createSelector(
  currentSectionIdDomainSelector,
  currentArticleIdSelector,
  entitiesTypeSelector('articles'),
  (sectionId, articleId, articlesById) => {
    if (articleId) {
      const articleSectionId = getProp(
        articlesById,
        `${articleId}.sectionid`,
        '',
      );
      return String(articleSectionId);
    }
    return sectionId;
  },
);

// Section CONTENT
const sectionsByIdDomainSelector = state => state.sectionsById;

const currentSectionByIdSelector = createSelector(
  sectionsByIdDomainSelector,
  currentSectionIdSelector,
  (byId, id) => getProp(byId, id, {}),
);

export const currentSectionIsFetchingSelector = createSelector(
  currentSectionByIdSelector,
  section => getProp(section, 'isFetching', false),
);

export const currentSectionSelector = createSelector(
  entitiesTypeSelector('sections'),
  currentSectionIdDomainSelector,
  (byId, id) => getProp(byId, id, {}),
);

// Section ARTICLES

const sectionArticlesBySectionIdDomainSelector = state =>
  state.sectionArticlesBySectionId;

const currentSectionArticlesBySectionIdSelector = createSelector(
  sectionArticlesBySectionIdDomainSelector,
  currentSectionIdSelector,
  (byId, id) => getProp(byId, id, {}),
);

const currentSectionArticlesIdsSelector = createSelector(
  currentSectionArticlesBySectionIdSelector,
  sectionArticlesObject => getProp(sectionArticlesObject, 'articles', []),
);

export const currentSectionArticlesSelector = createSelector(
  currentSectionArticlesIdsSelector,
  entitiesTypeSelector('articles'),
  (ids, articles) => at(articles, ids) || [],
);

export const currentSectionArticlesIsFetchingSelector = createSelector(
  currentSectionArticlesBySectionIdSelector,
  sectionArticlesObject => getProp(sectionArticlesObject, 'isFetching', false),
);
