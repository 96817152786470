import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { theme } from '../../theme';
import { rem, em } from 'polished';
import { mb } from '@sportnet/ui/lib/Themes/utilities';

const PageTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PageTitleText = styled.h1`
  font-weight: 800;
  text-transform: uppercase;
  font-family: ${theme.secondaryFont};
  font-size: ${theme.mdTitle};
  color: ${theme.secondary};
`;

const PageTitleIcon = styled('img')`
  vertical-align: middle;
  display: inline-block;
  margin-right: ${rem(8)};
  height: ${em(38)};
  width: ${em(38)};
  ${mb('m')} {
    height: ${em(46)};
    width: ${em(46)};
  }
`;

export const PageTitle = ({ icon, children }) => {
  return (
    <PageTitleWrapper>
      {icon && <PageTitleIcon src={icon} />}
      <PageTitleText>{children}</PageTitleText>
    </PageTitleWrapper>
  );
};

PageTitle.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.string,
};

export const PagePerex = styled.p`
  font-weight: 500;
  color: ${theme.secondary};
  margin-top: 0;
  text-align: justify;
`;
