import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { browserHistory } from 'react-router';
import { ThemeProvider } from 'styled-components';

import smoothscroll from 'smoothscroll-polyfill';

import Routes from './routes';
import configureStore from './configureStore';

import { theme } from './theme';
import GlobalStyles from './globalStyles';

// kick off the polyfill!
smoothscroll.polyfill();

let state = {};
if (typeof window !== 'undefined') {
  state = window.__REDUX_STATE__;
}
const store = configureStore(state !== '{{SSR_INITIAL_STATE}}' ? state : {});
ReactDOM.hydrate(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <Routes history={browserHistory} />
      </>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
