import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { __ } from '../../utilities';

const Wrapper = styled.div`
  padding: ${rem(30)} ${rem(15)};
  display: flex;
  justify-content: center;
`;

const Copyright = styled.span`
  color: #fff;
  font-size: ${rem(12)};
`;

const FooterBottom = () => {
  return (
    <Wrapper>
      <Copyright>{__('copyright © 2018 sportnet.online')}</Copyright>
    </Wrapper>
  );
};

export default FooterBottom;
