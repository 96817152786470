const namespace = 'App';
// Section Tree
export const FETCH_SECTIONS_REQUEST = `${namespace}/FETCH_SECTIONS_REQUEST`;
export const FETCH_SECTIONS_FAILURE = `${namespace}/FETCH_SECTIONS_FAILURE`;
export const FETCH_SECTIONS_SUCCESS = `${namespace}/FETCH_SECTIONS_SUCCESS`;

// Path
export const REQUEST_PATH = `${namespace}/REQUEST_PATH`;
export const RECEIVE_PATH_ERROR = `${namespace}/RECEIVE_PATH_ERROR`;
export const RECEIVE_PATH_SUCCESS = `${namespace}/RECEIVE_PATH_SUCCESS`;

export const SET_PATHNAME = `${namespace}/SET_PATHNAME`;

// Static text
export const REQUEST_STATIC_TEXT = `${namespace}/REQUEST_STATIC_TEXT`;
export const RECEIVE_STATIC_TEXT_SUCCESS = `${namespace}/RECEIVE_STATIC_TEXT_SUCCESS`;
export const RECEIVE_STATIC_TEXT_ERROR = `${namespace}/RECEIVE_STATIC_TEXT_ERROR`;
