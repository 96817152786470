import {
  REQUEST_SECTION,
  RECEIVE_SECTION_SUCCESS,
  RECEIVE_SECTION_ERROR,
  REQUEST_SECTION_ARTICLES,
  RECEIVE_SECTION_ARTICLES_SUCCESS,
  RECEIVE_SECTION_ARTICLES_ERROR,
  UPDATE_SECTION,
  SET_CURRENT_SECTION_ID,
} from './constants';

// Section CONTENT
const sectionsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_SECTION:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: true,
        },
      };
    case RECEIVE_SECTION_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: false,
          error: null,
        },
      };
    case UPDATE_SECTION:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: false,
          error: null,
        },
      };
    case RECEIVE_SECTION_ERROR:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};

// Section ARTICLES
const sectionArticlesBySectionIdReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_SECTION_ARTICLES:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: true,
        },
      };
    case RECEIVE_SECTION_ARTICLES_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          articles: action.payload.articleIds,
          isFetching: false,
          error: null,
        },
      };
    case RECEIVE_SECTION_ARTICLES_ERROR:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};

const setCurrentSectionIdReducer = (state = null, action) => {
  if (action.type === SET_CURRENT_SECTION_ID) {
    return action.payload.id;
  }
  return state;
};

export {
  sectionsByIdReducer,
  sectionArticlesBySectionIdReducer,
  setCurrentSectionIdReducer,
};
