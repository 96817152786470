import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme } from '../../theme';
import { getSectionUrl, getPictureUrl } from '../../utilities';
import Loading from '../../components/Loading';
import { SectionTreeType } from '../../containers/Home/propTypes';
import { RouterLink } from './BasicPackage';

const Wrapper = styled.div`
  padding: 0 ${rem(15)};
`;

const SectionsWrapper = styled.div`
  max-width: ${rem(1200)};
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-gap: 2rem;
  margin: 70px auto;

  ${({ theme }) => theme.media.lg`
    max-width: ${rem(900)};
  `};

  ${({ theme }) => theme.media.md`
    flex-direction: column;
    max-width: ${rem(768)};
  `};
`;

const Section = styled.div`
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  &:first-child {
    margin-left: 0;
  }

  ${({ theme }) => theme.media.md`
    max-width: 100%;
    margin-left: 0;
    align-content: center;

    &:last-child {
      margin-bottom: 0
    }
  `};
`;

const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: ${rem(30)};
  align-items: flex-start;

  ${({ theme }) => theme.media.md`
  flex-direction: column;
  align-self: center;
    align-items: center;
  `};
`;

const Icon = styled.img`
  width: ${rem(57)};
  ${({ theme }) => theme.media.md`
      margin-bottom: ${rem(15)};
    `};
`;

const Title = styled.h3`
  font-family: ${theme.secondaryFont};
  text-transform: uppercase;
  margin: 0 0 0 ${rem(10)};
  font-size: ${theme.smTitle};
  align-self: center;

  ${({ theme }) => theme.media.md`
  text-align: center;
  margin-left: 0;
  `};
`;

const Description = styled.p`
  font-family: ${theme.primaryFont};
  color: #999;
  font-size: ${rem(13)};
  margin: 0;
  line-height: 1.5;

  ${({ theme }) => theme.media.md`
      text-align: center;
    `};
`;

const HomeSections = props => {
  const sections = props.sections.map(item => {
    const img = getPictureUrl(item, 200, null);
    return (
      <Section key={item._id}>
        <TitleWrapper>
          {img && <Icon src={img} alt={item.name} />}
          <Title>
            <RouterLink to={getSectionUrl(item)}>{item.name}</RouterLink>
          </Title>
        </TitleWrapper>
        <Description>{item.perex}</Description>
      </Section>
    );
  });
  const isLoading = props.isFetching && props.sections.length === 0;
  return (
    <Wrapper>
      {isLoading ? <Loading /> : <SectionsWrapper>{sections}</SectionsWrapper>}
    </Wrapper>
  );
};

HomeSections.propTypes = {
  sections: SectionTreeType.isRequired,
  isFetching: PropTypes.bool,
};

export default HomeSections;
