import CsmApi from '../../CsmApi';
import {
  FETCH_SECTIONS_REQUEST,
  FETCH_SECTIONS_FAILURE,
  FETCH_SECTIONS_SUCCESS,
  REQUEST_PATH,
  RECEIVE_PATH_SUCCESS,
  RECEIVE_PATH_ERROR,
  REQUEST_STATIC_TEXT,
  RECEIVE_STATIC_TEXT_SUCCESS,
  RECEIVE_STATIC_TEXT_ERROR,
  SET_PATHNAME,
} from './constants';
import { contentSpaceSelector } from './selectors';
import { staticTextSchema } from '../../schemas';
import { normalize } from 'normalizr';

// Sections tree
export const requestSections = () => ({
  type: FETCH_SECTIONS_REQUEST,
  payload: {},
});

export const receiveSectionsFailure = error => ({
  type: FETCH_SECTIONS_FAILURE,
  payload: {
    error,
  },
});

export const receiveSectionsSuccess = tree => ({
  type: FETCH_SECTIONS_SUCCESS,
  payload: {
    tree,
  },
});

export const loadSectionsTree = () => {
  return (dispatch, getState) => {
    dispatch(requestSections());
    const contentSpace = contentSpaceSelector(getState());
    return CsmApi.getPublicSectionTreeByIdOrUniqId(contentSpace, 'MAINMENU')
      .then(data => {
        dispatch(receiveSectionsSuccess(data.tree));
      })
      .catch(error => {
        dispatch(receiveSectionsFailure(error));
      });
  };
};

// Sections path
export const requestPath = id => ({
  type: REQUEST_PATH,
  payload: {
    id,
  },
});

export const receivePathSuccess = (id, path) => ({
  type: RECEIVE_PATH_SUCCESS,
  payload: {
    id,
    path,
  },
});

export const receivePathError = (id, error) => ({
  type: RECEIVE_PATH_ERROR,
  payload: {
    id,
    error,
  },
});

export const loadPath = sectionId => {
  return (dispatch, getState) => {
    dispatch(requestPath(sectionId));
    const contentSpace = contentSpaceSelector(getState());
    return CsmApi.getPathForSectionId(contentSpace, sectionId)
      .then(data => {
        dispatch(receivePathSuccess(sectionId, data.path));
      })
      .catch(error => {
        dispatch(receivePathError(sectionId, error));
      });
  };
};

// Static text
export const requestStaticText = cid => ({
  type: REQUEST_STATIC_TEXT,
  payload: {
    cid,
  },
});

export const receiveStaticTextSuccess = (cid, entities) => ({
  type: RECEIVE_STATIC_TEXT_SUCCESS,
  payload: {
    cid,
    entities,
  },
});

export const receiveStaticTextError = (cid, error) => ({
  type: RECEIVE_STATIC_TEXT_ERROR,
  payload: {
    cid,
    error,
  },
});

export const loadStaticText = cid => {
  return (dispatch, getState) => {
    dispatch(requestStaticText(cid));
    const contentSpace = contentSpaceSelector(getState());
    return CsmApi.getPublicStaticContentById(contentSpace, cid)
      .then(data => {
        const normalized = normalize(data, staticTextSchema);
        dispatch(
          receiveStaticTextSuccess(normalized.result, normalized.entities),
        );
      })
      .catch(error => {
        dispatch(receiveStaticTextError(cid, error));
      });
  };
};

export const setPathName = pathName => {
  return dispatch => {
    dispatch(savePath(pathName));
  };
};

export const savePath = pathName => ({
  type: SET_PATHNAME,
  payload: {
    pathName,
  },
});
