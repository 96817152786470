const namespace = 'Home';
// Articles
export const REQUEST_ARTICLES = `${namespace}/REQUEST_ARTICLES`;
export const RECEIVE_ARTICLES_SUCCESS = `${namespace}/RECEIVE_ARTICLES_SUCCESS`;
export const RECEIVE_ARTICLES_ERROR = `${namespace}/RECEIVE_ARTICLES_ERROR`;

// Section Tree
export const REQUEST_SECTION_TREE = `${namespace}/REQUEST_SECTION_TREE`;
export const RECEIVE_SECTION_TREE_SUCCESS = `${namespace}/RECEIVE_SECTION_TREE_SUCCESS`;
export const RECEIVE_SECTION_TREE_ERROR = `${namespace}/RECEIVE_SECTION_TREE_ERROR`;

// Sectionby uniqId
export const REQUEST_SECTION = `${namespace}/REQUEST_SECTION`;
export const RECEIVE_SECTION_SUCCESS = `${namespace}/RECEIVE_SECTION_SUCCESS`;
export const RECEIVE_SECTION_ERROR = `${namespace}/RECEIVE_SECTION_ERROR`;
