import Calendars from '../assets/sectionIcons/calendars.png';
import Competitions from '../assets/sectionIcons/competitions.png';
import Crm from '../assets/sectionIcons/crm.png';
import Csm from '../assets/sectionIcons/csm.png';
import Events from '../assets/sectionIcons/events.png';
import Courier from '../assets/sectionIcons/courier.png';
import Mediamanager from '../assets/sectionIcons/mediamanager.png';
import Shop from '../assets/sectionIcons/shop.png';
import Testing from '../assets/sectionIcons/testing.png';
import Video from '../assets/sectionIcons/video.png';
import Votes from '../assets/sectionIcons/votes.png';

export const sectionIconMap = {
  calendars: Calendars,
  competitions: Competitions,
  crm: Crm,
  csm: Csm,
  events: Events,
  courier: Courier,
  mediamanager: Mediamanager,
  shop: Shop,
  testing: Testing,
  video: Video,
  votes: Votes,
};
