import CsmApi from '../../CsmApi';
import {
  REQUEST_ARTICLES,
  RECEIVE_ARTICLES_SUCCESS,
  RECEIVE_ARTICLES_ERROR,
  REQUEST_SECTION_TREE,
  RECEIVE_SECTION_TREE_SUCCESS,
  RECEIVE_SECTION_TREE_ERROR,
  REQUEST_SECTION,
  RECEIVE_SECTION_SUCCESS,
  RECEIVE_SECTION_ERROR,
} from './constants';
import { contentSpaceSelector } from '../App/selectors';
import { articleSchema } from '../../schemas';
import { normalize } from 'normalizr';
import { sectionSchema } from '../../schemas';

// Articles by smart tag

export const requestArticles = smartTag => ({
  type: REQUEST_ARTICLES,
  payload: {
    smartTag,
  },
});

export const receiveArticlesSuccess = (smartTag, articleIds, entities) => ({
  type: RECEIVE_ARTICLES_SUCCESS,
  payload: {
    smartTag,
    articleIds,
    entities,
  },
});

export const receiveArticlesError = (smartTag, error) => ({
  type: RECEIVE_ARTICLES_ERROR,
  payload: {
    smartTag,
    error,
  },
});

export const loadArticlesBySmartTag = smartTag => {
  return (dispatch, getState) => {
    dispatch(requestArticles(smartTag));
    const contentSpace = contentSpaceSelector(getState());
    return CsmApi.getPublicArticles(contentSpace, {
      smarttags: [smartTag],
    })
      .then(data => {
        const normalized = normalize(data.articles, [articleSchema]);
        dispatch(
          receiveArticlesSuccess(
            smartTag,
            normalized.result,
            normalized.entities,
          ),
        );
      })
      .catch(error => {
        dispatch(receiveArticlesError(smartTag, error));
      });
  };
};

// Sections tree by UniqId

export const requestSectionTree = uniqId => ({
  type: REQUEST_SECTION_TREE,
  payload: {
    uniqId,
  },
});

export const receiveSectionTreeSuccess = (uniqId, section, tree) => ({
  type: RECEIVE_SECTION_TREE_SUCCESS,
  payload: {
    uniqId,
    section,
    tree,
  },
});

export const receiveSectionTreeError = (uniqId, error) => ({
  type: RECEIVE_SECTION_TREE_ERROR,
  payload: {
    uniqId,
    error,
  },
});

export const loadSectionTreeByUniqId = uniqId => {
  return (dispatch, getState) => {
    dispatch(requestSectionTree(uniqId));
    const contentSpace = contentSpaceSelector(getState());
    return CsmApi.getPublicSectionTreeByIdOrUniqId(contentSpace, uniqId)
      .then(data => {
        dispatch(receiveSectionTreeSuccess(uniqId, data.section, data.tree));
      })
      .catch(error => {
        dispatch(receiveSectionTreeError(uniqId, error));
      });
  };
};

// Sections By UniqId

export const requestSection = uniqId => ({
  type: REQUEST_SECTION,
  payload: {
    uniqId,
  },
});

export const receiveSectionSuccess = (uniqId, sectionId, entities) => ({
  type: RECEIVE_SECTION_SUCCESS,
  payload: {
    uniqId,
    sectionId,
    entities,
  },
});

export const receiveSectionError = (uniqId, error) => ({
  type: RECEIVE_SECTION_ERROR,
  payload: {
    uniqId,
    error,
  },
});

export const loadSectionByUniqId = uniqId => {
  return (dispatch, getState) => {
    dispatch(requestSection(uniqId));
    const contentSpace = contentSpaceSelector(getState());
    return CsmApi.getPublicSectionByIdOrUniqId(contentSpace, uniqId)
      .then(data => {
        const normalized = normalize(
          { ...data.section, content: data.content || {} },
          sectionSchema,
        );
        dispatch(
          receiveSectionSuccess(uniqId, normalized.result, normalized.entities),
        );
      })
      .catch(error => {
        dispatch(receiveSectionError(uniqId, error));
      });
  };
};
